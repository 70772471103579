import { Button, Col, PageHeader, Row, Spin } from "antd";
import { FormConfigsInterface, enhancedQuestionInputs } from "@utils/profileFormConfigs";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
    useAddSurveyQuestionMutation,
    useGetSurveyQuery,
    useGetSurveySlotsQuery
} from "@store/surveys/surveys";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual";
import React, { useEffect, useState } from "react";
import { SurveyQuestionFormItems } from "./types";
import { isWithSlots } from "@utils/isWithSlots";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import { questionInputs } from "./form/new-question-inputs";
import { selectUser } from "@store/slices/user";
import { useSelector } from "react-redux";

const NewQuestion: React.FC = () => {
    const { id: surveyId } = useParams();
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();
    const { identity } = useSelector(selectUser);

    const [isQuestionType1Selected, setIsQuestionType1Selected] = useState<boolean>(false);
    const [isOpenQuestionSelected, setIsOpenQuestionSelected] = useState<boolean>(false);

    const {
        data: survey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        error: errorSurvey
    } = useGetSurveyQuery(surveyId!);

    const {
        data: futureSlots,
        isLoading: isLoadingSlots,
        isFetching: isFetchingSlots,
        error: errorSlots
    } = useGetSurveySlotsQuery(
        { surveyId: surveyId!, unpublishedOnly: true },
        { skip: !!!surveyId || survey === undefined || isWithSlots(survey?.profile) === false }
    );

    const [addSurveyQuestion, { isLoading: isLoadingSurveyQuestion }] =
        useAddSurveyQuestionMutation();

    useEffect(() => {
        if (form) {
            try {
                form.setFieldValue(
                    "is_radio",
                    identity?.data.profile?.general_configs?.question_default_values?.[
                        "is_radio"
                    ] || false
                );
                form.setFieldValue(
                    "is_icon",
                    identity?.data.profile?.general_configs?.question_default_values?.["is_icon"] ||
                        false
                );
            } catch {}
        }
    }, [form, identity]);

    const handleOnSubmit = async (values: SurveyQuestionFormItems) => {
        await performMutation({
            mutation: async () =>
                await addSurveyQuestion({
                    surveyId,
                    ...values
                }).unwrap(),
            successMessage: "The survey question was added successfully",
            errorMessage: "There was an error while creating the survey question",
            onSuccessCallback: () => navigate(`/survey/${surveyId}/questions`),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            formFieldsResetter: () => form.resetFields()
        });
    };

    const formInputs = identity?.data
        ? identity.data.is_admin
            ? questionInputs({
                  isMultipleSlotEnabled: isWithSlots(survey?.profile),
                  futureSlots: futureSlots || [],
                  isQuestionType1Selected: isQuestionType1Selected,
                  isOpenQuestionSelected: isOpenQuestionSelected
              })
            : enhancedQuestionInputs({
                  inputs: questionInputs({
                      isMultipleSlotEnabled: isWithSlots(survey?.profile),
                      futureSlots: futureSlots || [],
                      isQuestionType1Selected: isQuestionType1Selected,
                      isOpenQuestionSelected: isOpenQuestionSelected
                  }),
                  formConfigs: identity?.data.profile?.form_configs?.question as
                      | FormConfigsInterface
                      | null
                      | undefined,
                  canDisableTitle: false
              })
        : null;

    const error = errorSlots || errorSurvey;
    const loading =
        isLoadingSurvey ||
        isFetchingSurvey ||
        isLoadingSurveyQuestion ||
        isLoadingSlots ||
        isFetchingSlots;

    if (identity?.data && !identity?.data.is_new_questions_visible)
        return <Navigate to={`/survey/${surveyId}/questions`} />;
    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader title="New survey question" />
            <Row justify="center">
                <Col xs={23}>
                    <MultilingualForm
                        inputs={Array.isArray(formInputs) ? formInputs : []}
                        form={form}
                        onValuesChange={(_, values) => {
                            setIsQuestionType1Selected(values?.question_type === 1);
                            setIsOpenQuestionSelected(values.is_open === true);
                        }}
                        onSubmit={handleOnSubmit}
                        actions={
                            formInputs
                                ? [
                                      <Button
                                          key="submit"
                                          type="primary"
                                          htmlType="submit"
                                          data-testid="new-question-submit"
                                      >
                                          Create new survey question
                                      </Button>
                                  ]
                                : undefined
                        }
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default NewQuestion;
