import { MultilingualFormInput, MultilingualFormInputTypes } from "@components/forms/multilingual";

import type { ProfileType } from "@store/profiles/types";
import { Typography } from "antd";
import { questionInputs } from "src/pages/surveys/form/new-question-inputs";
import { surveyInputs } from "../pages/surveys/form/new-survey-inputs";

const surveyConfigInputs = surveyInputs({
    timezones: [],
    slugPath: "",
    isAdmin: false,
    profiles: [],
    profileId: 1,
    identity: undefined
});
const questionConfigInputs = questionInputs({
    isMultipleSlotEnabled: false,
    futureSlots: [],
    isQuestionType1Selected: false,
    isOpenQuestionSelected: false
});

const isVisibleNameSuffixModifier = "__is_visible";
const editableNameSuffixModifier = "__editable";

const mapSurveyInputsToFormConfigInputs = (
    inputs: MultilingualFormInput[],
    includeTitle: boolean
): MultilingualFormInput[] => {
    const formattedInputs = inputs.map((item) => {
        //@ts-ignore
        return !!item?.rules?.find((rule) => rule?.required === true)
            ? item.name === "title" && includeTitle
                ? [
                      {
                          name: item.name + editableNameSuffixModifier,
                          label: (
                              <>
                                  {item.label}
                                  <Typography.Text type="secondary">: is editable?</Typography.Text>
                              </>
                          ),
                          type: "switch" as MultilingualFormInputTypes,
                          multilingual: false,
                          valueSuffixModifier: editableNameSuffixModifier
                      }
                  ]
                : null
            : [
                  {
                      name: item.name + isVisibleNameSuffixModifier,
                      label: (
                          <>
                              {item.label}
                              <Typography.Text type="secondary">: is visible?</Typography.Text>
                          </>
                      ),
                      type: "switch" as MultilingualFormInputTypes,
                      multilingual: false,
                      valueSuffixModifier: isVisibleNameSuffixModifier
                  },
                  {
                      name: item.name + editableNameSuffixModifier,
                      label: (
                          <>
                              {item.label}
                              <Typography.Text type="secondary">: is editable?</Typography.Text>
                          </>
                      ),
                      type: "switch" as MultilingualFormInputTypes,
                      multilingual: false,
                      valueSuffixModifier: editableNameSuffixModifier
                  }
              ];
    });
    const filteredSurveyConfigInputs = formattedInputs.filter((input) => input !== null) as {
        name: string;
        label: JSX.Element;
        type: MultilingualFormInputTypes;
        multilingual: boolean;
        valueSuffixModifier: string;
    }[][];
    const surveyConfigInputs = filteredSurveyConfigInputs.reduce((current, prev) => {
        return [...current, ...prev];
    }, []);

    return surveyConfigInputs;
};

export const surveyConfigInputsList = mapSurveyInputsToFormConfigInputs(surveyConfigInputs, false);
export const questionConfigInputsList = mapSurveyInputsToFormConfigInputs(
    questionConfigInputs,
    true
);

export const getFeededFormValuesFormConfigs = (
    profileData: ProfileType,
    formType: "survey" | "question"
) => {
    const inputs = formType === "survey" ? surveyConfigInputsList : questionConfigInputsList;

    const formValues = inputs.reduce((prev, current: MultilingualFormInput) => {
        return {
            ...prev,
            [current.name]:
                //@ts-ignore
                profileData.form_configs?.[formType]?.[
                    //@ts-ignore
                    current?.name?.replace(current?.valueSuffixModifier, "")
                ]?.[
                    //@ts-ignore
                    current?.valueSuffixModifier.replace("__", "")
                ] || false
        };
    }, {});

    return formValues;
};

export const getFormValuesFormConfigs = (
    values: { [key: string]: boolean },
    formType: "survey" | "question"
) => {
    const inputs = formType === "survey" ? surveyConfigInputs : questionConfigInputs;
    const inputsValues = inputs.reduce((prev, current) => {
        return {
            ...prev,
            [current.name]: {
                name: current.label,
                is_visible:
                    values[current.name + isVisibleNameSuffixModifier] !== undefined
                        ? values[current.name + isVisibleNameSuffixModifier]
                        : false,
                editable:
                    values[current.name + editableNameSuffixModifier] !== undefined
                        ? values[current.name + editableNameSuffixModifier]
                        : false
            }
        };
    }, {});

    return inputsValues;
};

export interface FormConfigsInterface {
    [key: string]: {
        name: string;
        editable: boolean;
        is_visible: boolean;
    };
}

/**
 * Returns the question inputs to which has been applied the configurations of the profile.
 * This conrtrols rthe visibility and the editable status of the field.
 * If a field is not visible it is not returned at all from this function and therefore
 * not rendered in the form
 */
export const enhancedQuestionInputs = ({
    inputs,
    formConfigs,
    canDisableTitle
}: {
    inputs: MultilingualFormInput[];
    formConfigs: FormConfigsInterface | null | undefined;
    canDisableTitle: boolean;
}): MultilingualFormInput[] => {
    if (!formConfigs) return [];

    const enhancedInputs = inputs.map((input) => {
        //@ts-ignore
        if (!!input.rules?.find((rule) => rule.required === true)) {
            if (
                input.name === "title" &&
                canDisableTitle &&
                typeof formConfigs[input.name]?.editable === "boolean" &&
                formConfigs[input.name]?.editable !== true
            ) {
                return {
                    ...input,
                    inputProps: input.inputProps
                        ? { ...input.inputProps, disabled: true }
                        : { disabled: true }
                };
            } else return input;
        } else if (formConfigs[input.name]?.is_visible === false) return null;
        else if (
            typeof formConfigs[input.name]?.editable === "boolean" &&
            formConfigs[input.name]?.editable !== true
        ) {
            return {
                ...input,
                inputProps: input.inputProps
                    ? { ...input.inputProps, disabled: true }
                    : { disabled: true }
            };
        } else return input;
    });

    const fileredEnhancedInputs = enhancedInputs.filter(
        (input) => input !== null
    ) as MultilingualFormInput[];

    return fileredEnhancedInputs;
};
