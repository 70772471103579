import { useEffect, useState } from "react";

export const useDebouncedSearchParam = () => {
    const [searchParam, setSearchParam] = useState<string>("");
    const [debouncedSearchParam, setDebouncedSearchParam] = useState<string>("");

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedSearchParam(searchParam);
        }, 700);
        return () => clearTimeout(timeout);
    }, [searchParam]);

    return { searchParam, setSearchParam, debouncedSearchParam };
};
