import {
    Button,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    PageHeader,
    Row,
    Select,
    Spin,
    Table,
    Tag,
    Typography
} from "antd";
import { DatePicker, Space } from "antd";
import { Navigate, useNavigate } from "react-router-dom";

import ErrorMessage from "@components/ErrorMessage";
import { Right } from "@components/layout";
import SyntaxHighlighter from "react-syntax-highlighter";
import { debounce } from "lodash";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import js_beautify from "js-beautify";
import { selectUser } from "@store/slices/user";
import styles from "./LogsList.module.scss";
import { useGetLogsQuery } from "@store/logs/logs";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

const { RangePicker } = DatePicker;

const columns = (modalSetter: Function) => [
    {
        title: "E-mail",
        dataIndex: "causer",
        key: "email",
        render: (causer: { email: string } | null) => {
            return causer?.email;
        }
    },
    {
        title: "Timestamp",
        dataIndex: "updated_at",
        key: "updated_at",
        render: (value: string) => {
            let timestamp;
            try {
                timestamp = moment(value).format("MMMM DD YYYY HH:mm");
            } catch {
                timestamp = value;
            }
            return timestamp;
        }
    },
    {
        title: "Subject",
        dataIndex: "subject_type",
        key: "subject_type",
        render: (value: string) => {
            let formattedValue = "";
            try {
                formattedValue = value.split("\\")?.[2];
            } catch {}
            return formattedValue;
        }
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "address",
        render: (value: string) => {
            let color;
            switch (value) {
                case "updated":
                    color = "blue";
                    break;
                case "created":
                    color = "green";
                    break;
                case "deleted":
                    color = "red";
                    break;
                default:
                    color = "orange";
            }
            return <Tag color={color}>{value}</Tag>;
        }
    },
    {
        title: "View changes",
        dataIndex: "properties",
        key: "properties",
        render: (value: { old: any; attributes: any }) => {
            return <Button onClick={() => modalSetter(value)}>View changes</Button>;
        }
    }
];

interface LogsFilters {
    causer_id: string;
    subject_type: string;
    subject_id: string;
    date_from: string;
    date_to: string;
}

const Details = () => {
    const navigate = useNavigate();
    const { identity } = useSelector(selectUser);
    const [page, setPage] = useState<number>(1);
    const [changesModalContent, setChangesModalContent] = useState<{
        old: any;
        attributes: any;
    } | null>(null);
    const [logsFilters, setLogFilters] = useState<LogsFilters>({
        causer_id: "",
        subject_type: "",
        subject_id: "",
        date_from: "",
        date_to: ""
    });

    const logsFilterSetter = debounce((args: { value: string; key: string }[]) => {
        args.forEach(({ value, key }) => {
            setLogFilters((ps: LogsFilters) => {
                const nextState = { ...ps, [key]: value };
                return nextState;
            });
        });
    }, 400);

    useEffect(() => {
        setPage(1);
    }, [logsFilters]);

    const filters = Object.fromEntries(Object.entries(logsFilters).filter(([_, v]) => v !== ""));

    const {
        data: logsData,
        isLoading: isLoadingLogsData,
        isFetching: isFetchingLogsData,
        error: errorLogsData
    } = useGetLogsQuery({ page: page, ...filters });

    const error = errorLogsData;
    const loading = isLoadingLogsData;

    if (identity?.data && !identity?.data.is_admin) return <Navigate to={`/`} />;
    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <Layout className="logs-page">
                <Right>
                    <PageHeader onBack={() => navigate(`/`)} title="Logs" />
                    <Row justify="center">
                        <Col xs={23}>
                            <Form layout="vertical" className={styles["logs-list__filters"]}>
                                <div className={styles["logs-list__filters-container"]}>
                                    <Form.Item
                                        label="Filter by dates"
                                        className={styles["logs-list__filter-item"]}
                                    >
                                        <RangePicker
                                            showTime
                                            onChange={(range) => {
                                                logsFilterSetter([
                                                    {
                                                        value:
                                                            range?.[1]?.format(
                                                                "YYYY-MM-DD HH:mm"
                                                            ) || "",
                                                        key: "date_to"
                                                    },
                                                    {
                                                        value:
                                                            range?.[0]?.format(
                                                                "YYYY-MM-DD HH:mm"
                                                            ) || "",
                                                        key: "date_from"
                                                    }
                                                ]);
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Filter by subject"
                                        className={styles["logs-list__filter-item"]}
                                    >
                                        <Space direction="horizontal">
                                            <Select
                                                allowClear
                                                onChange={(val) => {
                                                    logsFilterSetter([
                                                        { value: val, key: "subject_type" }
                                                    ]);
                                                }}
                                                style={{ width: 150 }}
                                                placeholder="Subject type"
                                                options={[
                                                    {
                                                        label: "Question",
                                                        value: "App\\Models\\Question"
                                                    },
                                                    {
                                                        label: "Option",
                                                        value: "App\\Models\\Option"
                                                    },
                                                    {
                                                        label: "Survey",
                                                        value: "App\\Models\\Survey"
                                                    },
                                                    {
                                                        label: "Media",
                                                        value: "App\\Models\\Media"
                                                    },
                                                    {
                                                        label: "Style",
                                                        value: "App\\Models\\Style"
                                                    }
                                                ]}
                                            />
                                            <Input
                                                type="text"
                                                placeholder="Subject id"
                                                onChange={({ target }) =>
                                                    logsFilterSetter([
                                                        { value: target.value, key: "subject_id" }
                                                    ])
                                                }
                                            />
                                        </Space>
                                    </Form.Item>
                                    <Form.Item
                                        label="Filter by causer"
                                        className={styles["logs-list__filter-item"]}
                                    >
                                        <Space direction="horizontal">
                                            <Input
                                                type="text"
                                                placeholder="Causer id"
                                                onChange={({ target }) =>
                                                    logsFilterSetter([
                                                        { value: target.value, key: "causer_id" }
                                                    ])
                                                }
                                            />
                                        </Space>
                                    </Form.Item>
                                </div>
                            </Form>
                            <Table
                                bordered
                                pagination={{
                                    position: ["topRight", "bottomRight"],
                                    pageSize: logsData?.pagination.perPage,
                                    total: logsData?.pagination.total,
                                    current: page,
                                    onChange: (newPage) => {
                                        setPage && setPage(newPage);
                                    }
                                }}
                                loading={!isLoadingLogsData && isFetchingLogsData}
                                columns={columns(setChangesModalContent)}
                                dataSource={logsData?.logs || []}
                            />
                        </Col>
                    </Row>
                </Right>
                <Modal
                    width="70%"
                    title="Changes"
                    onCancel={() => setChangesModalContent(null)}
                    onOk={() => setChangesModalContent(null)}
                    open={!!changesModalContent}
                    okButtonProps={{
                        style: { display: "none" }
                    }}
                    cancelButtonProps={{
                        children: "Close"
                    }}
                >
                    {changesModalContent?.old && (
                        <>
                            <Typography.Title type="secondary" level={5}>
                                Old values
                            </Typography.Title>
                            <SyntaxHighlighter language="javascript" style={docco}>
                                {js_beautify(JSON.stringify(changesModalContent?.old))}
                            </SyntaxHighlighter>
                        </>
                    )}
                    {changesModalContent?.attributes && (
                        <>
                            <Typography.Title type="secondary" level={5}>
                                Updated values
                            </Typography.Title>
                            <SyntaxHighlighter language="javascript" style={docco}>
                                {js_beautify(JSON.stringify(changesModalContent?.attributes))}
                            </SyntaxHighlighter>
                        </>
                    )}
                </Modal>
            </Layout>
        </Spin>
    );
};

export default Details;
