import { useEffect, useState } from "react";

import { FormInstance } from "antd";
import { debounce } from "lodash";
import { useCheckSlugValidityMutation } from "@store/surveys/surveys";

export const useCheckSlug = ({
    formInstance,
    slugFromSurvey,
    surveyId,
    profileId
}: {
    formInstance: FormInstance;
    slugFromSurvey?: string | null;
    surveyId?: string;
    profileId: number | null;
}) => {
    const [checkSlug] = useCheckSlugValidityMutation();
    const [validSlug, setValidSlug] = useState<string | null>(null);
    const [slugToValidate, setSlugToValidate] = useState<string | null>(null);

    useEffect(() => {
        if (slugToValidate === null || profileId === null) return;
        (async () => {
            try {
                if (surveyId)
                    await checkSlug({
                        slug: slugToValidate,
                        survey_id: surveyId,
                        profile_id: profileId
                    }).unwrap();
                else await checkSlug({ slug: slugToValidate, profile_id: profileId }).unwrap();
                setValidSlug(slugToValidate);
                formInstance.setFields([
                    {
                        name: "slug",
                        errors: []
                    }
                ]);
            } catch (error: any) {
                setValidSlug(null);
                const slugErrors = error?.data?.errors?.slug;
                formInstance.setFields([
                    {
                        name: "slug",
                        errors: slugErrors
                    }
                ]);
            }
        })();
    }, [slugToValidate, profileId]);

    useEffect(() => {
        if (slugFromSurvey) setValidSlug(slugFromSurvey);
    }, [slugFromSurvey]);

    const slugToValidateSetter = (slug: string) => setSlugToValidate(slug);
    const slugToValidateSetterDebounced = debounce(slugToValidateSetter, 800);
    const resetSlug = () => setSlugToValidate(null);

    return {
        validSlug,
        setSlugForValidation: slugToValidateSetterDebounced,
        resetSlug
    };
};
