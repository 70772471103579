import "./styles/global.css";
import "./styles/overwrite-antd.less";

import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { getToken } from "@utils/auth";
import { stickToken } from "@store/slices/user";
import store from "./store";

const token = getToken();
if (token) {
    store.dispatch(stickToken(token));
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppRoutes />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
