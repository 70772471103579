import { ProfileType, QuestionsConfigType, StyleConfigType } from "@store/profiles/types";

import { MultilingualFormInput } from "@components/forms/multilingual";
import { DomainType } from "@store/surveys/types";

export const profileDetailInputs = ({
    styleConfigs,
    questionsConfigs,
    domains,
    isCreateMode
}: {
    styleConfigs: StyleConfigType[];
    questionsConfigs: QuestionsConfigType[];
    domains: DomainType[];
    isCreateMode: boolean;
}): MultilingualFormInput[] => [
    {
        name: "name",
        label: "Profile name",
        multilingual: false,
        rules: [{ required: true, message: "Please insert a name for the profile" }]
    },
    {
        name: "style_config_id",
        label: "Styles configurations",
        multilingual: false,
        type: "select",
        options: mapConfigsToOptions(styleConfigs)
    },
    {
        name: "questions_config_id",
        label: "Questions configurations",
        multilingual: false,
        type: "select",
        options: mapConfigsToOptions(questionsConfigs)
    },
    {
        name: "domain_id",
        label: "Associated domain",
        multilingual: false,
        type: "select",
        options: mapDomainsToOptions(domains),
        alertMessage: !isCreateMode
            ? "It is no longer possible to edit the domain at this stage. Contact a system administrator if you wish to change the domain."
            : "You can only assign a domain when creating a profile. Changing the domain afterwards can only be done by a system administrator (be careful!!)",
        inputProps: {
            disabled: isCreateMode ? false : true
        },
        rules: [{ required: true, message: "Please set a domain for the profile" }]
    },
    {
        name: "is_style_tab_visible",
        label: "Set styles tab visible",
        multilingual: false,
        type: "switch"
    },
    {
        name: "is_media_tab_visible",
        label: "Set media tab visible",
        multilingual: false,
        type: "switch"
    },
    {
        name: "is_new_questions_visible",
        label: "Set possibility to add new questions to survey",
        multilingual: false,
        type: "switch"
    },
    {
        name: "is_multiple_slot_enabled",
        label: "Set the possibility to publish and unpublish entire question batches",
        multilingual: false,
        type: "switch",
        alertMessage: !isCreateMode
            ? "It is no longer possible to change the publication logic at this stage. Contact a system admin if you have questions."
            : "You can only set the publication logic when creating a profile. NOBODY can change this after the profile has been created (be careful!!)",
        inputProps: {
            disabled: isCreateMode ? false : true
        }
    },
    {
        name: "is_option_attach_media_visible",
        label: "Set the possibility to attach media to a question option",
        multilingual: false,
        type: "switch"
    }
];

const mapDomainsToOptions = (domains: DomainType[]) => {
    return domains.map((domain) => {
        return {
            label: domain.value,
            value: String(domain.id)
        };
    });
};

const mapConfigsToOptions = (configs: StyleConfigType[] | QuestionsConfigType[]) => {
    return configs.map((config) => {
        return {
            label: config.title,
            value: String(config.id)
        };
    });
};

export const getFeededFormValues = (values: ProfileType) => {
    return {
        ...values,
        name: values.name,
        style_config_id: values.style_config_id ? String(values.style_config_id) : null,
        questions_config_id: values.questions_config_id ? String(values.questions_config_id) : null,
        domain_id: values.domain_id ? String(values.domain_id) : null
    };
};
