import NoSelected from "@components/NoSelected";
import React from "react";
import { selectUser } from "@store/slices/user";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const HomeSurveyQuestions: React.FC = () => {
    const { id } = useParams();
    const { identity } = useSelector(selectUser);

    if (identity?.data.is_new_questions_visible)
        return (
            <NoSelected
                title="Please select a question or create a"
                buttonText="New question"
                link={`/survey/${id}/questions/new`}
            />
        );
    else return <NoSelected title="Please select a question" />;
};

export default HomeSurveyQuestions;
