import adminApi from "@store/admin/admin";
import { configureStore } from "@reduxjs/toolkit";
import logsApi from "@store/logs/logs";
import profilesApi from "@store/profiles/profiles";
import surveysApi from "@store/surveys/surveys";
import userReducer from "./slices/user";
import metricsApi from "./metrics/metrics";

const store = configureStore({
    reducer: {
        user: userReducer,
        [surveysApi.reducerPath]: surveysApi.reducer,
        [profilesApi.reducerPath]: profilesApi.reducer,
        [logsApi.reducerPath]: logsApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [metricsApi.reducerPath]: metricsApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            surveysApi.middleware,
            profilesApi.middleware,
            logsApi.middleware,
            adminApi.middleware,
            metricsApi.middleware
        )
});

export default store;

export interface Error422 {
    errors: {
        [key: string]: string[];
    };
    message: string;
}

export interface RequestAction {
    loading: boolean;
    success?: boolean;
    item?: any;
    error: any;
}
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
