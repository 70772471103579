import { Button, Col, PageHeader, Row, Spin, Tabs, Typography } from "antd";
import { useEffect, useState, useRef } from "react";
import {
    useCreateProfileMutation,
    useGetProfileQuery,
    useGetQuestionsConfigsQuery,
    useGetStyleConfigsQuery,
    useUpdateProfileMutation
} from "@store/profiles/profiles";
import { useNavigate, useParams } from "react-router";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Link } from "react-router-dom";
import MultilingualForm from "@components/forms/multilingual";
import { ProfileType } from "@store/profiles/types";
import { getFeededFormValues } from "./form/profile-details";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import { profileDetailInputs } from "./form/profile-details";
import { scrollToFirstFormError } from "@utils/scrollToFirstFormError";
import {
    surveyConfigInputsList,
    questionConfigInputsList,
    getFeededFormValuesFormConfigs,
    getFormValuesFormConfigs
} from "@utils/profileFormConfigs";
import StickyActionBanner from "@components/StickyActionBanner/StickyActionBanner";
import { useGetDomainsQuery } from "@store/surveys/surveys";

const Details = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { profile } = useParams();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState<string>("general");

    const [form] = MultilingualForm.useForm();
    const [surveyConfigsForm] = MultilingualForm.useForm();
    const [questionsConfigsForm] = MultilingualForm.useForm();

    const isCreate = profile === "new";

    const {
        data: profileData,
        isLoading: loadingProfileData,
        isFetching: isFetchingProfileData,
        error: errorProfileData,
        refetch: refetchProfileData
    } = useGetProfileQuery({ profile: profile }, { skip: !!!profile || profile === "new" });

    const {
        data: questionsConfigs,
        isLoading: isLoadingQuestionsConfigs,
        isFetching: isFetchingQuestionsConfigs,
        error: errorQuestionsConfig
    } = useGetQuestionsConfigsQuery();

    const {
        data: domains,
        isLoading: isLoadingDomains,
        isFetching: isFetchingDomains,
        error: errorDomains
    } = useGetDomainsQuery();

    const {
        data: styleConfigs,
        isLoading: isLoadingStyleConfigs,
        isFetching: isFetchingStyleConfigs,
        error: errorStyleConfigs
    } = useGetStyleConfigsQuery();

    const [updateProfile, { isLoading: isLoadingUpdateProfile }] = useUpdateProfileMutation();
    const [createProfile, { isLoading: isLoadingCreateProfile }] = useCreateProfileMutation();

    useEffect(() => {
        if (form && profileData && profile !== "new") {
            form.setFieldsValue({ ...getFeededFormValues(profileData) });

            surveyConfigsForm.setFieldsValue(getFeededFormValuesFormConfigs(profileData, "survey"));
            questionsConfigsForm.setFieldsValue(
                getFeededFormValuesFormConfigs(profileData, "question")
            );
        } else if (form && profile === "new") {
            form.resetFields();
            surveyConfigsForm.resetFields();
            questionsConfigsForm.resetFields();
        }
    }, [form, profileData, profile]);

    const handleOnSubmitProfileConfigs = async (values: ProfileType) => {
        const surveyFormConfigs = getFormValuesFormConfigs(
            surveyConfigsForm.getFieldsValue(),
            "survey"
        );
        const questionsFormConfigs = getFormValuesFormConfigs(
            questionsConfigsForm.getFieldsValue(),
            "question"
        );

        await performMutation({
            mutation: async () => {
                isCreate
                    ? await createProfile({
                          ...values,
                          form_configs: {
                              survey: surveyFormConfigs,
                              question: questionsFormConfigs
                          }
                      }).unwrap()
                    : await updateProfile({
                          profile,
                          ...values,
                          form_configs: {
                              survey: surveyFormConfigs,
                              question: questionsFormConfigs
                          }
                      }).unwrap();
            },
            successMessage: `Profile ${isCreate ? "created" : "updated"} successfully`,
            errorMessage: `There was an error while ${
                isCreate ? "creating" : "updating"
            } the profile`,
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
                if (profileData) {
                    surveyConfigsForm.setFieldsValue(
                        getFeededFormValuesFormConfigs(profileData, "survey")
                    );
                    questionsConfigsForm.setFieldsValue(
                        getFeededFormValuesFormConfigs(profileData, "question")
                    );
                }
            },
            onSuccessCallback: () => {
                isCreate ? navigate("/profiles") : refetchProfileData();
            },
            onErrorCallback: () => scrollToFirstFormError()
        });
    };

    const error = errorProfileData || errorQuestionsConfig || errorStyleConfigs || errorDomains;

    const loading =
        loadingProfileData ||
        isLoadingQuestionsConfigs ||
        isFetchingQuestionsConfigs ||
        isLoadingStyleConfigs ||
        isFetchingStyleConfigs ||
        isLoadingUpdateProfile ||
        isFetchingProfileData ||
        isLoadingCreateProfile ||
        isLoadingDomains ||
        isFetchingDomains;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <div ref={containerRef}>
            <Spin spinning={loading}>
                <PageHeader
                    onBack={() => navigate(`/profiles`)}
                    title={
                        isCreate ? (
                            "Create profile"
                        ) : (
                            <>
                                Update profile{" "}
                                <Typography.Text type="secondary">
                                    - {profileData?.name}
                                </Typography.Text>
                            </>
                        )
                    }
                    extra={
                        !isCreate
                            ? [
                                  <Link key="users" to={`/profiles/${profile}/users`}>
                                      <Button>Users</Button>
                                  </Link>
                              ]
                            : undefined
                    }
                />
                <Row justify="center">
                    <Col sm={23}>
                        <Tabs
                            type="card"
                            activeKey={activeKey}
                            onChange={(key) => setActiveKey(key)}
                            items={[
                                {
                                    key: "general",
                                    label: `General configurations`
                                },
                                {
                                    key: "survey_form",
                                    label: `Survey form configurations`
                                },
                                {
                                    key: "question_form",
                                    label: `Survey questions form configurations`
                                }
                            ]}
                        />
                        <MultilingualForm
                            style={{ display: activeKey === "general" ? "block" : "none" }}
                            form={form}
                            inputs={
                                questionsConfigs && styleConfigs && domains
                                    ? profileDetailInputs({
                                          styleConfigs,
                                          questionsConfigs,
                                          domains,
                                          isCreateMode: isCreate
                                      })
                                    : []
                            }
                            onSubmit={handleOnSubmitProfileConfigs}
                            hideTabsIfSingleLanguage={true}
                        />
                        <MultilingualForm
                            style={{ display: activeKey === "survey_form" ? "block" : "none" }}
                            form={surveyConfigsForm}
                            inputs={surveyConfigInputsList}
                            onSubmit={() => {}}
                            hideTabsIfSingleLanguage={true}
                        />
                        <MultilingualForm
                            style={{ display: activeKey === "question_form" ? "block" : "none" }}
                            form={questionsConfigsForm}
                            inputs={questionConfigInputsList}
                            onSubmit={() => {}}
                            hideTabsIfSingleLanguage={true}
                        />
                        <StickyActionBanner parentReference={containerRef}>
                            <Button
                                key="submit"
                                type="primary"
                                onClick={() => {
                                    setActiveKey("general");
                                    form.submit();
                                }}
                            >
                                {isCreate ? "Create" : "Update "} profile and the related form
                                configurations
                            </Button>
                        </StickyActionBanner>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default Details;
