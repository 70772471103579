import React, { useEffect, useState } from "react";

import styles from "./StickyActionBanner.module.scss";

interface StickyActionBannerProps {
    children: React.ReactNode;
    parentReference: React.RefObject<HTMLDivElement>;
}

const StickyActionBanner: React.FC<StickyActionBannerProps> = ({ children, parentReference }) => {
    const [width, setWidth] = useState<number | null>(null);

    const calculateWidth = () => {
        const width = parentReference?.current?.clientWidth || null;
        setWidth(width);
    };

    useEffect(() => {
        calculateWidth();
        window.addEventListener("resize", calculateWidth);
        return () => window.removeEventListener("resize", calculateWidth);
    }, []);

    return (
        <div className={styles["sticky-action-banner"]}>
            <div className={styles["sticky-action-banner__spacer"]}></div>
            <div
                style={{ width: width === null ? "auto" : width + "px" }}
                className={styles["sticky-action-banner__banner"]}
            >
                {children}
            </div>
        </div>
    );
};

export default StickyActionBanner;
