import { Button, PageHeader, Popconfirm } from "antd";
import { Layout, Left, Right } from "@components/layout";
import { Link, Outlet } from "react-router-dom";
import { useDeleteUserMutation, useGetProfileUsersQuery } from "@store/profiles/profiles";
import { useNavigate, useParams } from "react-router";

import Card from "@components/Card/Card";
import { DeleteOutlined } from "@ant-design/icons";
import ErrorMessage from "@components/ErrorMessage";
import { ProfileUserType } from "@store/profiles/types";
import moment from "moment";
import { performMutation } from "@utils/performMutation";

export default () => {
    const navigate = useNavigate();
    const { profile, user } = useParams();

    const {
        data: users,
        isFetching: isFetchingUsers,
        isLoading: isLoadingUsers,
        error: errorUsers,
        refetch
    } = useGetProfileUsersQuery({ profile: profile }, { skip: !!!profile });

    const [deleteUser, { isLoading: isLoadingDeleteUser }] = useDeleteUserMutation();

    const handleDeleteUser = async (user: string) => {
        await performMutation({
            mutation: async () => await deleteUser({ user }).unwrap(),
            successMessage: "User deleted successfully",
            errorMessage: "There was an error while deleting the user",
            onSuccessCallback: () => {
                navigate(`/profiles/${profile}/users`);
                refetch();
            }
        });
    };

    const error = errorUsers;
    const loading = isFetchingUsers || isLoadingUsers || isLoadingDeleteUser;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <>
            <PageHeader onBack={() => navigate(`/profiles/${profile}`)} title="Profile users" />
            <Layout height="calc(100vh - 200px)">
                <Left
                    height="calc(100vh - 200px)"
                    action={
                        <Link
                            to={`/profiles/${profile}/users/new`}
                            data-testid="new-question-button"
                        >
                            <Button type="primary" size="small">
                                + New
                            </Button>
                        </Link>
                    }
                    title="Users"
                    loadingItems={loading}
                    items={users}
                    renderItem={(profileUser: ProfileUserType) => (
                        <Link
                            key={profileUser.id}
                            to={`/profiles/${profile}/users/${profileUser.id}`}
                        >
                            <Card
                                published={
                                    profileUser.created_at
                                        ? moment(profileUser.created_at).fromNow()
                                        : undefined
                                }
                                updated={
                                    profileUser.updated_at
                                        ? moment(profileUser.updated_at).fromNow()
                                        : undefined
                                }
                                title={profileUser.name}
                                description={profileUser.email}
                                selected={user !== undefined && String(profileUser.id) === user}
                                deleteElement={
                                    <Popconfirm
                                        title="Are you sure to delete this user?"
                                        okText="Delete"
                                        onConfirm={() => handleDeleteUser(String(profileUser.id))}
                                    >
                                        <DeleteOutlined />
                                    </Popconfirm>
                                }
                            />
                        </Link>
                    )}
                />
                <Right height="calc(100vh - 150px)">
                    <Outlet />
                </Right>
            </Layout>
        </>
    );
};
