import { Button, Col, PageHeader, Row, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { Error422 } from "@store/index";
import MultilingualForm from "@components/forms/multilingual";
import { ProfileUserType } from "@store/profiles/types";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { newUserInputs } from "./form/users";
import { performMutation } from "@utils/performMutation";
import { useCreateUserMutation } from "@store/profiles/profiles";

export default () => {
    const navigate = useNavigate();
    const { profile } = useParams();
    const [form] = MultilingualForm.useForm();

    const [createUser, { isLoading: isLoadingCreateUser }] = useCreateUserMutation();

    const handleOnSubmit = async (values: ProfileUserType) => {
        await performMutation({
            mutation: async () => await createUser({ ...values, profile_id: profile }).unwrap(),
            successMessage: "The user was created successfully",
            errorMessage: "There was an error while creating the user",
            onSuccessCallback: () => navigate(`/profiles/${profile}/users`),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            formFieldsResetter: () => form.resetFields()
        });
    };

    const loading = isLoadingCreateUser;

    return (
        <Spin spinning={loading}>
            <PageHeader title={`Create a new user`} />
            <Row justify="center">
                <Col xs={23}>
                    <MultilingualForm
                        inputs={newUserInputs()}
                        onSubmit={handleOnSubmit}
                        hideTabsIfSingleLanguage={true}
                        form={form}
                        actions={[
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                data-testid="new-question-submit"
                            >
                                Create new user
                            </Button>
                        ]}
                    />
                </Col>
            </Row>
        </Spin>
    );
};
