import { useGetProfileQuery } from "@store/profiles/profiles";

export const useCanCreateSurvey = (profileId: number | undefined) => {
    const {
        data: profileData,
        isLoading: isLoadingProfileData,
        isFetching: isFetchingProfileData,
        isUninitialized: isUninitializedProfileData,
        error: errorProfileData,
        refetch: refetchProfileData
    } = useGetProfileQuery({ profile: String(profileId) }, { skip: profileId === undefined });

    const isProfileInformationLoading =
        profileId !== undefined && (isUninitializedProfileData || isLoadingProfileData);

    const availableSurveys =
        typeof profileData?.license?.available_surveys === "number"
            ? profileData?.license?.available_surveys
            : null;

    const cannotCreateSurvey =
        isProfileInformationLoading ||
        (typeof profileData?.license?.available_surveys === "number" &&
            profileData?.license?.available_surveys === 0);

    return {
        cannotCreateSurvey,
        errorProfileData,
        isUninitializedProfileData,
        refetchProfileData,
        availableSurveys
    };
};
