import React from "react";
import { Typography } from "antd";
import classNames from "classnames";
import styles from "@components/Card/Card.module.scss";

export interface CardProps {
    title: string;
    tags?: string[];
    published?: string;
    updated?: string;
    selected?: boolean;
    deleteElement?: React.ReactNode;
    duplicateElement?: React.ReactNode;
    description?: string;
    children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
    title,
    tags,
    published,
    deleteElement,
    duplicateElement,
    updated,
    children,
    description,
    selected = false
}) => {
    const className = classNames(styles.card, {
        [styles.selected]: selected
    });

    return (
        <div className={className}>
            <div className={styles.title}>{title}</div>
            {description && <Typography.Text type="secondary">{description}</Typography.Text>}
            <div className={styles.footer}>
                {tags &&
                    tags.map((tag) => (
                        <div key={tag} className={styles.tag}>
                            {tag}
                        </div>
                    ))}
            </div>
            {children && <div className={styles.children}>{children}</div>}
            <div className={styles.times}>
                {updated && <div className={styles.date}>updated: {updated}</div>}
                {published && <div className={styles.date}>published: {published}</div>}
            </div>
            {deleteElement && <div className={styles.delete}>{deleteElement}</div>}
            {duplicateElement && <div className={styles.duplicate}>{duplicateElement}</div>}
        </div>
    );
};

export default Card;
