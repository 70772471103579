import { Button, Drawer, Space, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
    useGetSurveyQuestionOptionQuery,
    useGetSurveyQuestionQuery,
    useUpdateSurveyQuestionOptionMutation
} from "@store/surveys/surveys";
import { useNavigate, useParams } from "react-router";

import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual";
import { SurveyQuestionOptionFormItems } from "./types";
import { performMutation } from "@utils/performMutation";
import { questionOptionInputs } from "./form/question-option-inputs";
import ColorPicker from "@components/ColorPicker";

const UpdateSurveyQuestionOption: React.FC = () => {
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();
    const { id, questionId, optionId } = useParams();
    const [visible, setVisible] = useState<boolean>(false);
    const [colorValue, setColorValue] = useState<string | null>(null);

    const {
        data: surveyQuestionOption,
        isLoading: isLoadingSurveyQuestionOption,
        isFetching: isFetchingSurveyQuestionOption,
        error: errorSurveyQuestionOption,
        refetch: refetchQuestionOption
    } = useGetSurveyQuestionOptionQuery({ optionId }, { skip: !!!optionId });

    const {
        data: surveyQuestion,
        isLoading: isLoadingSurveyQuestion,
        isFetching: isFetchingSurveyQuestion,
        error: errorSurveyQuestion
    } = useGetSurveyQuestionQuery({ questionId });

    const [updateOption, { isLoading: isLoadingUpdateOption }] =
        useUpdateSurveyQuestionOptionMutation();

    useEffect(() => {
        setVisible(true);
    }, []);

    useEffect(() => {
        if (surveyQuestionOption && form) {
            form.setFieldsValue(surveyQuestionOption);
            setColorValue(surveyQuestionOption.text_color);
        }
    }, [surveyQuestionOption, form]);

    const handleCloseDrawer = () => {
        setVisible(false);
        setTimeout(() => {
            navigate(`/survey/${id}/questions/${questionId}/options`);
        }, 400);
    };

    const handleOnSubmit = async (values: SurveyQuestionOptionFormItems) => {
        await performMutation({
            mutation: async () =>
                await updateOption({
                    optionId,
                    ...values,
                    text_color: colorValue
                }).unwrap(),
            successMessage: "The survey question option was updated successfully",
            errorMessage: "There was an error while updating the survey question oprtion",
            onSuccessCallback: () => {
                handleCloseDrawer();
                refetchQuestionOption();
            }
        });
    };

    const error = errorSurveyQuestionOption || errorSurveyQuestion;
    const loading =
        isLoadingUpdateOption ||
        isLoadingSurveyQuestionOption ||
        isFetchingSurveyQuestionOption ||
        isLoadingSurveyQuestion ||
        isFetchingSurveyQuestion;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Drawer width={550} open={visible} onClose={handleCloseDrawer} title="Update option">
            <Spin spinning={loading}>
                {surveyQuestion && (
                    <>
                        <MultilingualForm
                            form={form}
                            inputs={questionOptionInputs({
                                isIcon: !!surveyQuestion?.is_icon,
                                isQuestionSlider: surveyQuestion.question_type === 1
                            })}
                            onSubmit={handleOnSubmit}
                            style={{ marginBottom: 0 }}
                        />
                        {surveyQuestion.question_type === 1 && (
                            <>
                                <div style={{ position: "relative", width: "fit-content" }}>
                                    <Space direction="vertical">
                                        <div className={"ant-form-item-label"}>Text color</div>
                                        <ColorPicker
                                            color={colorValue || "#fff"}
                                            onChangeComplete={setColorValue}
                                        />
                                        {!!!colorValue && (
                                            <Tag
                                                style={{
                                                    position: "absolute",
                                                    width: "calc(100% + 4px)",
                                                    left: -2,
                                                    top: -2,
                                                    padding: "3px 10px",
                                                    pointerEvents: "none"
                                                }}
                                            >
                                                No color selected. Click to select
                                            </Tag>
                                        )}
                                    </Space>
                                </div>
                                <div style={{ paddingTop: 25 }} />
                            </>
                        )}
                        <Button key="submit" type="primary" htmlType="submit" onClick={form.submit}>
                            Update
                        </Button>
                    </>
                )}
            </Spin>
        </Drawer>
    );
};

export default UpdateSurveyQuestionOption;
