import {
    Button,
    Col,
    Form,
    Input,
    Layout,
    PageHeader,
    Row,
    Select,
    Spin,
    Table,
    message
} from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import ErrorMessage from "@components/ErrorMessage";
import { MetricsType } from "@store/metrics/types";
import { Right } from "@components/layout";
import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import { saveAs } from "file-saver";
import { selectUser } from "@store/slices/user";
import styles from "./MetricsPage.module.scss";
import { useGetMetricsQuery } from "@store/metrics/metrics";
import { useGetProfilesQuery } from "@store/profiles/profiles";
import { useSelector } from "react-redux";
import { utcToTimeZone } from "@utils/timezones";

const dateFormat = "YYYY/MM/DD HH:mm";

const columns = () => [
    {
        title: "Id",
        dataIndex: "id",
        key: "id",
        render: (_: any, row: MetricsType) => {
            return row.id;
        }
    },
    {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (_: any, row: MetricsType) => {
            return row.title?.["en"];
        }
    },
    {
        title: "Owner",
        dataIndex: "owner_name",
        key: "owner_name",
        render: (_: any, row: MetricsType) => {
            return row.owner_name;
        }
    },
    {
        title: "Profile",
        dataIndex: "profile_id",
        key: "profile",
        render: (_: any, row: MetricsType) => {
            return row?.profile.name;
        }
    },
    {
        title: "Url",
        dataIndex: "slug",
        key: "slug",
        render: (_: any, row: MetricsType) => {
            return `https://${row.profile?.domain?.value || ""}/${row.slug || ""}`;
        }
    },
    {
        title: "Timezone",
        dataIndex: "timezone",
        key: "timezone",
        render: (_: any, row: MetricsType) => {
            return row.timezone || "UTC";
        }
    },
    {
        title: "Created at",
        dataIndex: "created_at",
        key: "created_at",
        render: (_: any, row: MetricsType) => {
            let timestamp;
            try {
                timestamp = moment(row.created_at).isValid()
                    ? moment(utcToTimeZone({ time: row.created_at!, zone: row.timezone })).format(
                          dateFormat
                      )
                    : row.created_at;
            } catch {
                timestamp = row.created_at;
            }
            return timestamp;
        }
    },
    {
        title: "Open at",
        dataIndex: "open_at",
        key: "open_at",
        render: (_: any, row: MetricsType) => {
            let timestamp;
            try {
                timestamp = moment(row.open_at).isValid()
                    ? moment(utcToTimeZone({ time: row.open_at!, zone: row.timezone })).format(
                          dateFormat
                      )
                    : row.open_at;
            } catch {
                timestamp = row.open_at;
            }
            return timestamp;
        }
    },
    {
        title: "Close at",
        dataIndex: "close_at",
        key: "close_at",
        render: (_: any, row: MetricsType) => {
            let timestamp;
            try {
                timestamp = moment(row.close_at).isValid()
                    ? moment(utcToTimeZone({ time: row.close_at!, zone: row.timezone })).format(
                          dateFormat
                      )
                    : row.close_at;
            } catch {
                timestamp = row.close_at;
            }
            return timestamp;
        }
    }
];

interface LogsFilters {
    profile_id: string;
    owner_name: string;
}

const MetricsPage = () => {
    const navigate = useNavigate();
    const { identity } = useSelector(selectUser);
    const { token } = useSelector(selectUser);
    const [page, setPage] = useState<number>(1);
    const [downloadingExport, toggleDownloadingExport] = useState<boolean>(false);
    const [logsFilters, setLogFilters] = useState<LogsFilters>({
        profile_id: "",
        owner_name: ""
    });

    const logsFilterSetter = debounce((args: { value: string; key: string }[]) => {
        args.forEach(({ value, key }) => {
            setLogFilters((ps: LogsFilters) => {
                const nextState = { ...ps, [key]: value };
                return nextState;
            });
        });
    }, 400);

    useEffect(() => {
        setPage(1);
    }, [logsFilters]);

    const filters = Object.fromEntries(Object.entries(logsFilters).filter(([_, v]) => v !== ""));

    const {
        data: metricsData,
        isLoading: isLoadingMetricsData,
        isFetching: isFetchingMetricsData,
        error: errorMetricsData
    } = useGetMetricsQuery({ page: page, ...filters });

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData
    } = useGetProfilesQuery(
        { step: null },
        { skip: identity === undefined || !identity?.data?.is_admin }
    );

    const handleDownloadFile = async () => {
        if (!downloadingExport) {
            toggleDownloadingExport(true);

            const url = `${process.env.REACT_APP_API_BASE_URL}/admin/surveys/download-metrics`;

            try {
                const res = await axios.get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: "blob",
                    params: {
                        profile_id: logsFilters.profile_id,
                        owner_name: logsFilters.owner_name
                    }
                });
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                const profileId = logsFilters.profile_id
                    ? `-profileId-${logsFilters.profile_id}`
                    : ``;
                const ownerName = logsFilters.owner_name
                    ? `-ownerName-${logsFilters.owner_name}`
                    : ``;
                const fileName = `exported-metrics${profileId}${ownerName}`;
                saveAs(blob, fileName);
            } catch (error: any) {
                message.error(error.message);
            }
            toggleDownloadingExport(false);
        }
    };

    const error = errorMetricsData || errorProfilesData;
    const loading =
        isFetchingMetricsData ||
        isLoadingMetricsData ||
        isFetchingProfilesData ||
        isLoadingProfilesData;

    if (identity?.data && !identity?.data.is_admin) return <Navigate to={`/`} />;
    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <Layout className="logs-page">
                <Right>
                    <PageHeader
                        onBack={() => navigate(`/`)}
                        title="Metrics"
                        extra={<Button onClick={handleDownloadFile}>Download metrics</Button>}
                    />
                    <Row justify="center">
                        <Col xs={23}>
                            <Form layout="vertical" className={styles["logs-list__filters"]}>
                                <div className={styles["logs-list__filters-container"]}>
                                    <Form.Item label="Filter by profile">
                                        <Select
                                            allowClear
                                            onChange={(val) => {
                                                logsFilterSetter([
                                                    { value: val, key: "profile_id" }
                                                ]);
                                            }}
                                            style={{ width: 150 }}
                                            placeholder=""
                                            options={profilesData?.profiles.map((profile) => {
                                                return {
                                                    label: profile.name,
                                                    value: profile.id
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Filter by owner">
                                        <Input
                                            type="text"
                                            placeholder="Owner email"
                                            onChange={({ target }) =>
                                                logsFilterSetter([
                                                    { value: target.value, key: "owner_name" }
                                                ])
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </Form>
                            <Table
                                bordered
                                pagination={{
                                    position: ["topRight", "bottomRight"],
                                    pageSize: metricsData?.pagination.perPage,
                                    total: metricsData?.pagination.total,
                                    current: page,
                                    onChange: (newPage) => {
                                        setPage && setPage(newPage);
                                    }
                                }}
                                loading={!isLoadingMetricsData && isFetchingMetricsData}
                                columns={columns()}
                                dataSource={metricsData?.data || []}
                            />
                        </Col>
                    </Row>
                </Right>
            </Layout>
        </Spin>
    );
};

export default MetricsPage;
