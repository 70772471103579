import { getMe, selectUser } from "@store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import FullPageSpin from "@components/FullPageSpin";
import profilesApi from "@store/profiles/profiles";
import surveysApi from "@store/surveys/surveys";
import { useEffect } from "react";
import { useQueryParams } from "src/hooks/useQueryParams";

const WithAuth = (Component: any) => (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token, identity, loading, error } = useSelector(selectUser);

    const resetPasswordToken = useQueryParams("token");

    const isPasswordResetRoute =
        location.pathname.split("/")?.[1] === "reset-password" && resetPasswordToken;

    useEffect(() => {
        if (!identity && token) {
            dispatch(profilesApi.util.resetApiState());
            dispatch(surveysApi.util.resetApiState());
            dispatch(getMe());
        }

        if (!token && !identity && !isPasswordResetRoute) {
            navigate(`/login${location.search}`);
        }
    }, [token, identity, location.search, dispatch, navigate]);

    useEffect(() => {
        if (
            error &&
            error.code === 401 &&
            location.pathname !== "/login" &&
            !isPasswordResetRoute
        ) {
            navigate(`/login?${location.search}`);
        }
    }, [error, location.search, location.pathname, navigate]);

    return (!identity && token) || loading ? (
        <FullPageSpin>
            <div />
        </FullPageSpin>
    ) : (
        <Component {...props} />
    );
};

export default WithAuth;
