import { Input, Modal } from "antd";
import { useState } from "react";

interface OwnerConfirmationModalProps {
    open: boolean;
    onCancel: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    onOk: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    afterClose?: (() => void) | undefined;
    passphrase: string | undefined;
    isLoading: boolean;
}

const OwnerConfirmationModal: React.FC<OwnerConfirmationModalProps> = ({
    open,
    onCancel,
    onOk,
    afterClose,
    passphrase,
    isLoading
}) => {
    const [okButtonDisabled, setOkButtonDisabled] = useState<boolean>(true);
    const verifyInputValue = (e: React.FormEvent<HTMLInputElement>) => {
        setOkButtonDisabled(e.currentTarget.value !== passphrase);
    };

    return (
        <Modal
            open={open}
            title="Please confirm owner email"
            onCancel={onCancel}
            onOk={onOk}
            okText={"Confirm"}
            confirmLoading={isLoading}
            afterClose={afterClose}
            okButtonProps={{ disabled: okButtonDisabled }}
        >
            <Input onChange={verifyInputValue} />
        </Modal>
    );
};

export default OwnerConfirmationModal;
