import {
    CSSStyle,
    CSSStyleKeyName,
    DomainType,
    GalleryItem,
    KeyName,
    MediaItem,
    PaginatedSurveys,
    SurveyQuestionOptionType,
    SurveyQuestionType,
    SurveySlotType,
    SurveyType
} from "./types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type { RootState } from "@store/index";

const SURVEYS = "Surveys";
const QUESTIONS = "Questions";
const SINGLE_QUESTION = "Single_question";
const OPTIONS = "Options";
export const ANSWERS = "Answers";
const MEDIAS = "Medias";
const GALLERY = "Gallery";
const CSS_STYLES = "CSS_Styles";
const SLOTS = "Slots";
const DOMAINS = "Domains";
const SURVEY_ANALYTICS = "Survey analytics";

const surveysApi = createApi({
    reducerPath: "surveys",
    tagTypes: [
        SURVEYS,
        QUESTIONS,
        SINGLE_QUESTION,
        OPTIONS,
        ANSWERS,
        MEDIAS,
        GALLERY,
        CSS_STYLES,
        SLOTS,
        DOMAINS,
        SURVEY_ANALYTICS
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/admin`,
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);
            headers.set("Accept", "application/json");

            return headers;
        }
    }),
    endpoints: (build) => ({
        // SURVEYS
        getSurveys: build.query<PaginatedSurveys, { page: number; searchParam: string }>({
            query: ({ page, searchParam }) => `/surveys?page=${page}&q=${searchParam}`,
            transformResponse: (res: BaseQueryResult<any>): any => ({
                surveys: res.data,
                pagination: {
                    total: res.meta.total,
                    perPage: res.meta.per_page,
                    current: res.meta.current_page
                }
            }),
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.surveys.map(({ id }: any) => ({ type: SURVEYS, id })),
                          { type: SURVEYS, id: "LIST" }
                      ]
                    : [{ type: SURVEYS, id: "LIST" }]
        }),
        getSurvey: build.query<SurveyType, string>({
            query: (id) => `/surveys/${id}`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        createSurvey: build.mutation({
            query: (body) => ({
                url: `/surveys`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: SURVEYS, id: "LIST" }, SURVEY_ANALYTICS]
        }),
        updateSurvey: build.mutation({
            query: ({ id, ...body }) => {
                return {
                    url: `/surveys/${id}`,
                    method: "PUT",
                    body
                };
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: SURVEYS, id: "LIST" }, SURVEY_ANALYTICS]
        }),
        deleteSurvey: build.mutation({
            query: (id) => ({
                url: `/surveys/${id}`,
                method: "DELETE"
            }),
            transformResponse: (res: BaseQueryResult<any>) => res,
            invalidatesTags: [{ type: SURVEYS, id: "LIST" }]
        }),
        getSurveyAnswers: build.query<
            any,
            { surveySlot: string | null; page: number; surveyId: number | string }
        >({
            query: ({ page = 1, surveyId, surveySlot }) => {
                if (!surveySlot) return `/surveys/${surveyId}/answers?page=${page}`;
                else
                    return `/surveys/${surveyId}/answers?page=${page}&survey_slot_id=${surveySlot}`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => ({
                answers: res.data,
                pagination: {
                    total: res.meta.total,
                    perPage: res.meta.per_page,
                    current: res.meta.current_page
                }
            }),
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.answers.map(({ id }: any) => ({ type: ANSWERS, id })),
                          { type: ANSWERS, id: "LIST" }
                      ]
                    : [{ type: ANSWERS, id: "LIST" }]
        }),
        // QUESTIONS
        getSurveyQuestions: build.query<SurveyQuestionType[], { surveyId: string | undefined }>({
            query: ({ surveyId }) => `/surveys/${surveyId}/questions`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: [QUESTIONS]
        }),
        addSurveyQuestion: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/questions`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [QUESTIONS, SLOTS]
        }),
        getSurveyQuestion: build.query<SurveyQuestionType, { questionId: string | undefined }>({
            query: ({ questionId }) => `/questions/${questionId}`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: () => [SINGLE_QUESTION]
        }),
        updateSurveyQuestion: build.mutation({
            query: ({ questionId, ...body }) => ({
                url: `/questions/${questionId}`,
                method: "PUT",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [QUESTIONS, SINGLE_QUESTION, SLOTS]
        }),
        updateQuestionsOrder: build.mutation({
            query: ({ surveyId, order }) => ({
                url: `/surveys/${surveyId}/questions/sort`,
                method: "POST",
                body: {
                    positions: order
                }
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [QUESTIONS]
        }),
        deleteSurveyQuestion: build.mutation({
            query: (questionId) => ({
                url: `/questions/${questionId}`,
                method: "DELETE"
            }),
            invalidatesTags: [QUESTIONS]
        }),
        // QUESTION OPTIONS
        getSurveyQuestionOptions: build.query<
            SurveyQuestionOptionType[],
            { questionId: string | undefined }
        >({
            query: ({ questionId }) => `/questions/${questionId}/options`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: OPTIONS, id })),
                          { type: OPTIONS, id: "LIST" }
                      ]
                    : [{ type: OPTIONS, id: "LIST" }]
        }),
        addSurveyQuestionOption: build.mutation({
            query: ({ questionId, ...body }) => ({
                url: `/questions/${questionId}/options`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        getSurveyQuestionOption: build.query<
            SurveyQuestionOptionType,
            { optionId: string | undefined }
        >({
            query: ({ optionId }) => `/options/${optionId}`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        updateSurveyQuestionOption: build.mutation({
            query: ({ optionId, ...body }) => ({
                url: `/options/${optionId}`,
                method: "PUT",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        deleteSurveyQuestionOption: build.mutation({
            query: (optionId) => ({
                url: `/options/${optionId}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        updateOptionsOrder: build.mutation({
            query: ({ questionId, order }) => ({
                url: `/questions/${questionId}/options/sort`,
                method: "POST",
                body: {
                    positions: order
                }
            }),
            invalidatesTags: [{ type: OPTIONS, id: "LIST" }]
        }),
        // MEDIA KEY NAMES
        getSurveyMediaKeyNames: build.query<KeyName[], string>({
            query: () => `/media-keynames`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        // MEDIAS
        getSurveyMedias: build.query<MediaItem[], string>({
            query: (id) => `/surveys/${id}/media`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: MEDIAS, id })),
                          { type: MEDIAS, id: "LIST" }
                      ]
                    : [{ type: MEDIAS, id: "LIST" }]
        }),
        attachSurveyMedias: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/attachMedia`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: MEDIAS, id: "LIST" }]
        }),
        detachSurveyMedias: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/detachMedia`,
                method: "DELETE",
                body
            }),
            invalidatesTags: [{ type: MEDIAS, id: "LIST" }]
        }),
        // GALLERY
        getSurveyGallery: build.query<GalleryItem[], string>({
            query: (id) => `/surveys/${id}/gallery`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: GALLERY, id })),
                          { type: GALLERY, id: "LIST" }
                      ]
                    : [{ type: GALLERY, id: "LIST" }]
        }),
        saveUploadedGalleryMedia: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `surveys/${surveyId}/media/save-uploaded`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: GALLERY, id: "LIST" }]
        }),
        // STYLES
        getCssStylesKeyNames: build.query<CSSStyleKeyName[], string>({
            query: () => `/style-keynames`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        getCssStyles: build.query<CSSStyle[], string>({
            query: (id) => `/surveys/${id}/styles`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: (result: any) =>
                result
                    ? [
                          ...result.map(({ id }: any) => ({ type: GALLERY, id })),
                          { type: CSS_STYLES, id: "LIST" }
                      ]
                    : [{ type: CSS_STYLES, id: "LIST" }]
        }),
        changeCssStyles: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/styles`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [{ type: CSS_STYLES, id: "LIST" }]
        }),
        // TIMEZONES
        getTimezones: build.query({
            query: () => `/timezones`,
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        checkSlugValidity: build.mutation({
            query: ({ ...body }) => ({
                url: `/surveys/check-slug`,
                method: "POST",
                body
            })
        }),
        // SLOTS
        getSurveySlots: build.query<
            SurveySlotType[],
            { surveyId: string; unpublishedOnly?: boolean }
        >({
            query: ({ surveyId, unpublishedOnly }) => {
                if (!unpublishedOnly) return `/surveys/${surveyId}/survey-slots`;
                else return `/surveys/${surveyId}/survey-slots?unpublished_only=true`;
            },
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: () => [SLOTS]
        }),
        createSurveySlot: build.mutation<
            any,
            {
                surveyId: number | string;
                title: string;
                configuration: { [key: string]: boolean | string };
                start_at: string | null;
                finish_at: string | null;
            }
        >({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/schedule-configuration`,
                method: "POST",
                body
            }),
            invalidatesTags: [QUESTIONS, SLOTS, SINGLE_QUESTION]
        }),
        updateSurveySlot: build.mutation<
            any,
            {
                surveyId: number | string;
                title: string;
                slotId: string | number;
                configuration: { [key: string]: boolean | string };
                start_at: string | null;
                finish_at: string | null;
            }
        >({
            query: ({ surveyId, slotId, ...body }) => {
                return {
                    url: `/surveys/${surveyId}/survey-slots/${slotId}`,
                    method: "PUT",
                    body
                };
            },
            invalidatesTags: [QUESTIONS, SLOTS, SINGLE_QUESTION]
        }),
        publishSurveySlot: build.mutation<any, any>({
            query: ({ surveyId, slotId, ...body }) => {
                return {
                    url: `/surveys/${surveyId}/survey-slots/${slotId}/publish`,
                    method: "POST",
                    body
                };
            },
            invalidatesTags: [QUESTIONS, SLOTS]
        }),
        deleteSurveySlot: build.mutation<any, any>({
            query: ({ surveyId, slotId }) => {
                return {
                    url: `/surveys/${surveyId}/survey-slots/${slotId}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [QUESTIONS, SLOTS, SINGLE_QUESTION]
        }),
        // GENERAL
        duplicateSurvey: build.mutation({
            query: ({ surveyId, ...body }) => ({
                url: `/surveys/${surveyId}/duplicate`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [SURVEYS]
        }),
        duplicateQuestion: build.mutation({
            query: ({ questionId, ...body }) => ({
                url: `/questions/${questionId}/duplicate`,
                method: "POST",
                body
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            invalidatesTags: [QUESTIONS, SLOTS]
        }),
        getDomains: build.query<DomainType[], void>({
            query: () => `/domains`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: () => [DOMAINS]
        }),
        attachOptionMedia: build.mutation<any, { optionId: string; mediaId: string | number }>({
            query: ({ optionId, mediaId }) => ({
                url: `/options/${optionId}/attachMedia?media_id=${mediaId}`,
                method: "POST"
            }),
            transformResponse: (res: BaseQueryResult<any>) => res.data
        }),
        detachOptionMedia: build.mutation<any, { optionId: string }>({
            query: ({ optionId }) => ({
                url: `/options/${optionId}/detachMedia`,
                method: "DELETE"
            })
        }),
        getSurveyAnalytics: build.query<
            {
                report_range_date: {
                    start_at: string | null;
                    finish_at: string | null;
                } | null;
                unique_visitors: number | null;
                is_final_report: boolean | null;
            },
            { surveyId: string }
        >({
            query: ({ surveyId }) => `/surveys/${surveyId}/analytics`,
            transformResponse: (res: BaseQueryResult<any>) => res.data,
            providesTags: () => [SURVEY_ANALYTICS]
        })
    })
});

export default surveysApi;

export const {
    useGetSurveysQuery,
    useGetSurveyQuery,
    useDeleteSurveyMutation,
    useCreateSurveyMutation,
    useUpdateSurveyMutation,
    useGetSurveyAnswersQuery,
    useGetSurveyQuestionsQuery,
    useAddSurveyQuestionMutation,
    useGetSurveyQuestionQuery,
    useUpdateSurveyQuestionMutation,
    useUpdateQuestionsOrderMutation,
    useDeleteSurveyQuestionMutation,
    useGetSurveyQuestionOptionsQuery,
    useAddSurveyQuestionOptionMutation,
    useGetSurveyQuestionOptionQuery,
    useUpdateSurveyQuestionOptionMutation,
    useDeleteSurveyQuestionOptionMutation,
    useUpdateOptionsOrderMutation,
    useGetSurveyMediaKeyNamesQuery,
    useGetSurveyMediasQuery,
    useGetSurveyGalleryQuery,
    useDetachSurveyMediasMutation,
    useAttachSurveyMediasMutation,
    useSaveUploadedGalleryMediaMutation,
    useGetCssStylesKeyNamesQuery,
    useGetCssStylesQuery,
    useChangeCssStylesMutation,
    useGetTimezonesQuery,
    useCheckSlugValidityMutation,
    useGetSurveySlotsQuery,
    useDuplicateSurveyMutation,
    useDuplicateQuestionMutation,
    useGetDomainsQuery,
    useAttachOptionMediaMutation,
    useDetachOptionMediaMutation,
    useGetSurveyAnalyticsQuery,
    useCreateSurveySlotMutation,
    useDeleteSurveySlotMutation,
    useUpdateSurveySlotMutation,
    usePublishSurveySlotMutation
} = surveysApi;
