import {
    PaginatedProfiles,
    ProfileType,
    ProfileUserType,
    QuestionsConfigType,
    StyleConfigType
} from "@store/profiles/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type { RootState } from "@store/index";

const PROFILES = "Profiles";
const QUESTIONS_CONFIGS = "Questions configs";
const STYLE_CONFIGS = "Style configs";
const USERS = "Users";

const surveysApi = createApi({
    reducerPath: "profiles",
    tagTypes: [PROFILES, QUESTIONS_CONFIGS, STYLE_CONFIGS, USERS],
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/admin`,
        responseHandler: "json",
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);
            headers.set("Accept", "application/json");

            return headers;
        }
    }),
    endpoints: (build) => ({
        getProfiles: build.query<
            PaginatedProfiles,
            { page?: number; searchTerm?: string; step?: number | null }
        >({
            query: ({ page = 1, searchTerm = "", step = 10 }) => {
                if (!step) return `/profiles`;
                else if (!searchTerm && step) return `/profiles?page=${page}&step=${step}`;
                else return `/profiles?page=${page}&step=${step}&q=${searchTerm}`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => ({
                profiles: res.data,
                pagination: {
                    total: res?.meta?.total,
                    perPage: res?.meta?.per_page,
                    current: res?.meta?.current_page
                }
            }),
            providesTags: () => [PROFILES]
        }),
        getProfile: build.query<ProfileType, { profile: string | undefined }>({
            query: ({ profile }) => {
                return `/profiles/${profile}`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: () => []
        }),
        getQuestionsConfigs: build.query<QuestionsConfigType[], void>({
            query: () => {
                return `/questions-configs`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: () => [QUESTIONS_CONFIGS]
        }),
        getStyleConfigs: build.query<StyleConfigType[], void>({
            query: () => {
                return `/style-configs`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: () => [STYLE_CONFIGS]
        }),
        createProfile: build.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/profiles`,
                    method: "POST",
                    body: body
                };
            },
            invalidatesTags: [PROFILES]
        }),
        updateProfile: build.mutation({
            query: ({ profile, ...body }) => {
                return {
                    url: `/profiles/${profile}`,
                    method: "PUT",
                    body: body
                };
            },
            invalidatesTags: [PROFILES]
        }),
        deleteProfile: build.mutation({
            query: ({ profile, ...body }) => {
                return {
                    url: `/profiles/${profile}`,
                    method: "DELETE",
                    body: body
                };
            },
            invalidatesTags: [PROFILES]
        }),
        getProfileUsers: build.query<ProfileUserType[], { profile: string | undefined }>({
            query: ({ profile }) => {
                return `/profiles/${profile}/users`;
            },
            transformResponse: (res: BaseQueryResult<any>): any => res.data,
            providesTags: () => [USERS]
        }),
        createUser: build.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/users`,
                    method: "post",
                    body: body
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => {
                return res.data;
            },
            invalidatesTags: [USERS]
        }),
        updateUser: build.mutation({
            query: ({ user, ...body }) => {
                return {
                    url: `/users/${user}`,
                    method: "put",
                    body: body
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => {
                return res.data;
            },
            invalidatesTags: [USERS]
        }),
        deleteUser: build.mutation({
            query: ({ user, ...body }) => {
                return {
                    url: `/users/${user}`,
                    method: "delete",
                    body: body
                };
            },
            invalidatesTags: () => [USERS]
        })
    })
});

export default surveysApi;

export const {
    useGetProfilesQuery,
    useGetProfileQuery,
    useCreateProfileMutation,
    useGetQuestionsConfigsQuery,
    useGetStyleConfigsQuery,
    useUpdateProfileMutation,
    useDeleteProfileMutation,
    useGetProfileUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation
} = surveysApi;
