import { Alert, Button, Divider, Popconfirm, Skeleton, Space, Tag, Typography } from "antd";
import { DeleteOutlined, WifiOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { SurveySlotType, SurveyType } from "@store/surveys/types";

import Card from "@components/Card";
import React from "react";
import moment from "moment";
import { utcToTimeZone } from "@utils/timezones";

interface SurveySlotCardProps {
    slot: SurveySlotType;
    survey: SurveyType | undefined;
    handleCUDSlot: (
        method: "post" | "put" | "delete",
        slotId?: string | number
    ) => (
        values?:
            | {
                  [key: string]: string | boolean;
              }
            | undefined
    ) => Promise<void>;
    handlePublishSlot: (slotId: number, keepOldSchedule: boolean) => Promise<void>;
}

const lableDateFormat = "DD-MM-YYYY HH:mm";

const SurveySlotCard: React.FC<SurveySlotCardProps> = ({
    slot,
    handleCUDSlot,
    survey,
    handlePublishSlot
}) => {
    const { id: surveyId, slotId } = useParams();

    if (survey === undefined) return <Skeleton />;

    const publishedAtTime = slot.published_at
        ? moment(
              utcToTimeZone({
                  time: slot.published_at,
                  zone: survey?.timezone
              })
          ).format(lableDateFormat)
        : "";

    const publishingTime = slot.start_at
        ? moment(
              utcToTimeZone({
                  time: slot.start_at,
                  zone: survey?.timezone
              })
          ).format(lableDateFormat)
        : "";

    const finishTime = slot.finish_at
        ? moment(
              utcToTimeZone({
                  time: slot.finish_at,
                  zone: survey?.timezone
              })
          ).format(lableDateFormat)
        : "";

    return (
        <Link to={`/survey/${surveyId}/slots/${slot.id}`} data-testid="slot-card">
            <Card
                title={slot.title || ""}
                selected={slotId !== undefined && String(slot.id) === slotId}
                deleteElement={
                    !!!slot.published_at && (
                        <Popconfirm
                            title="Are you sure you want to delete this question group?"
                            okText="Delete"
                            onConfirm={() => handleCUDSlot("delete", slot.id)()}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    )
                }
            >
                <Space direction="vertical">
                    <Space direction="horizontal">
                        <Typography.Text>
                            Is published:{" "}
                            <Tag color={!!slot.published_at ? "green" : "red"}>
                                {!!slot.published_at ? "Yes" : "No"}
                            </Tag>
                        </Typography.Text>
                        <Typography.Text>
                            Is Active:{" "}
                            <Tag
                                color={
                                    survey.current_survey_slot_ids?.includes(slot.id)
                                        ? "green"
                                        : "red"
                                }
                            >
                                {survey.current_survey_slot_ids?.includes(slot.id) ? "Yes" : "No"}
                            </Tag>
                        </Typography.Text>
                    </Space>
                    <Divider style={{ margin: 5 }} />
                    <Typography.Text>
                        {slot.published_at ? (
                            <Tag>
                                {"Published at: "}
                                {publishedAtTime}
                            </Tag>
                        ) : (
                            <>
                                {publishingTime && (
                                    <Tag>
                                        {"Publishing time: "}
                                        {publishingTime}
                                    </Tag>
                                )}
                            </>
                        )}
                    </Typography.Text>
                    {slot.finish_at && (
                        <Typography.Text>
                            <Tag>
                                {"Finish time: "}
                                {finishTime}
                            </Tag>
                        </Typography.Text>
                    )}
                    <Popconfirm
                        title={
                            <div>
                                <div style={{ marginBottom: "1rem" }}>
                                    <strong>
                                        Do you want to keep the old publish time as well?
                                    </strong>
                                    <br />
                                    If you choose <strong>"Yes"</strong>, a copy of the question
                                    group will be created and published immediatelly <br />
                                    leaving the original group to automatically publish at the
                                    scheduled time,
                                    <br />
                                    otherwise the current configuration will be overwritten
                                </div>
                                <div style={{ marginBottom: "1rem" }}>
                                    <Alert
                                        message={`Are you sure you saved all changes you made on the "${slot.title}" questions group?`}
                                        type="error"
                                    />
                                </div>
                            </div>
                        }
                        okText={"Yes, keep old publish time"}
                        cancelText={"No, ignore and overwrite old publish time"}
                        onConfirm={() => handlePublishSlot(slot.id, true)}
                        onCancel={() => handlePublishSlot(slot.id, false)}
                        disabled={!!slot.published_at}
                    >
                        <Button disabled={!!slot.published_at}>
                            <WifiOutlined />
                            Publish now
                        </Button>
                    </Popconfirm>
                </Space>
            </Card>
        </Link>
    );
};

export default SurveySlotCard;
