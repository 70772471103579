import { useEffect, useState } from "react";

import QRCode from "qrcode";

interface QRCodeGeneratorProps {
    children: React.ReactNode;
    baseUrl: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ children, baseUrl }) => {
    const [qr, setQr] = useState<string>();

    const generateQRCode = () => {
        QRCode.toDataURL(
            baseUrl,
            {
                width: 800,
                margin: 2
            },
            (err, url: string) => {
                if (err) return;
                setQr(url);
            }
        );
    };

    useEffect(() => {
        generateQRCode();
    }, [baseUrl]);

    return (
        <a type="text" href={qr} download="qrcode.png">
            {children}
        </a>
    );
};

export default QRCodeGenerator;
