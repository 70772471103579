import NoSelected from "@components/NoSelected";
import { selectUser } from "@store/slices/user";
import { useCanCreateSurvey } from "src/hooks/useCanCreateSurvey";
import { useSelector } from "react-redux";

const Home = () => {
    const { identity } = useSelector(selectUser);

    const { cannotCreateSurvey } = useCanCreateSurvey(identity?.data.profile?.id);

    return (
        <NoSelected
            title={
                cannotCreateSurvey
                    ? "Please select a survey to edit it"
                    : "Please select a survey or create a"
            }
            buttonText={cannotCreateSurvey ? undefined : "New survey"}
            link={cannotCreateSurvey ? undefined : "/survey/new"}
        />
    );
};

export default Home;
