import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ResetPasswordPayload } from "@store/admin/types";
import type { RootState } from "@store/index";

const adminApi = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
        responseHandler: "json",
        prepareHeaders: (headers, { getState }) => {
            const {
                user: { token }
            } = getState() as RootState;
            headers.set(`Authorization`, `Bearer ${token}`);
            headers.set("Accept", "application/json");

            return headers;
        }
    }),
    endpoints: (build) => ({
        requestPasswordReset: build.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/forgot-password`,
                    method: "POST",
                    body: body
                };
            }
        }),
        resetPassword: build.mutation<any, ResetPasswordPayload>({
            query: ({ ...body }) => {
                return {
                    url: `/reset-password`,
                    method: "POST",
                    body: body
                };
            }
        })
    })
});

export default adminApi;

export const { useResetPasswordMutation, useRequestPasswordResetMutation } = adminApi;
