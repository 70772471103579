import React, { Ref } from "react";

import { ColProps } from "antd/lib/col";
import Form from "./MultilingualForm";
import { FormInstance } from "antd";
import { Rule } from "rc-field-form/lib/interface";

export type MultilingualFormInputTypes =
    | "text"
    | "number"
    | "password"
    | "email"
    | "textarea"
    | "select"
    | "switch"
    | "date-picker"
    | "range-picker"
    | "rich-text-editor";

export interface SelectOption {
    label: any;
    value: string | number;
}

export interface MultilingualFormInput {
    name: string;
    label?: string | React.ReactElement<any, any>;
    rules?: Rule[];
    type?: MultilingualFormInputTypes;
    placeholder?: string;
    multilingual?: boolean;
    alertMessage?: string | undefined;
    mode?: "multiple" | "tags";
    options?: SelectOption[];
    grid?: {
        col: ColProps;
    };
    inputProps?: { [key: string]: any };
    formItemProps?: { [key: string]: any };
    isInvisible?: boolean;
}

export interface MultilingualFormProps {
    form?: FormInstance;
    onSubmit: (values: any) => void;
    style?: React.CSSProperties | undefined;
    inputs: MultilingualFormInput[];
    actions?: React.ReactElement[];
    ref?: Ref<FormInstance>;
    hideTabsIfSingleLanguage?: boolean;
    errors?: string;
    onValuesChange?: (changedValues: any, values: any) => void;
    errorFields?: { name: string; errors: string[]; warnings: string[] }[];
}

export default Form;
