import { Button, Form, List, Space, Switch, Tooltip } from "antd";
import { KeyName, MediaItem } from "@store/surveys/types";
import React, { useState } from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

interface MediaListItemProps {
    association: MediaItem | undefined;
    item: KeyName;
    imageBackground: { [key: string]: string };
    setKeyNameToUpload: (str: string) => void;
    setImageBackground: (ps: (ps: BackgroundSignature) => BackgroundSignature) => void;
    detatchMedia: (mediaInfo: {
        surveyId: string;
        media_id: string;
        fieldname: string;
    }) => Promise<any>;
}

export interface BackgroundSignature {
    [key: string]: string;
}

const MediaListItem: React.FC<MediaListItemProps> = ({
    association,
    item,
    detatchMedia,
    setKeyNameToUpload
}) => {
    const { id: surveyId } = useParams();
    const [background, setBackground] = useState<"white" | "black">("white");

    return (
        <List.Item
            style={{ alignItems: "flex-start" }}
            data-testid="media-listitem"
            actions={[
                association ? (
                    <Button
                        onClick={async () =>
                            await detatchMedia({
                                surveyId: surveyId!,
                                media_id: association.id,
                                fieldname: item.keyname
                            })
                        }
                        data-testid="detach-button"
                    >
                        Detach
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={() => setKeyNameToUpload(item.keyname)}
                        data-testid="attach-button"
                    >
                        Attach
                    </Button>
                )
            ]}
        >
            <List.Item.Meta
                title={item.description}
                description={
                    association?.url && (
                        <Form.Item
                            label={
                                <Space direction="horizontal">
                                    Background
                                    <Tooltip
                                        placement="top"
                                        title={
                                            "Only for image preview purpose, will NOT modify the image"
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Space>
                            }
                        >
                            <Switch
                                checkedChildren="white"
                                unCheckedChildren="black"
                                defaultChecked
                                size="default"
                                onChange={() =>
                                    setBackground((ps) => {
                                        if (ps === "white") return "black";
                                        else return "white";
                                    })
                                }
                            />
                        </Form.Item>
                    )
                }
            />
            <div
                style={{
                    display: "flex",
                    backgroundColor: background,
                    width: "20%"
                }}
            >
                {association && (
                    <img
                        style={{ width: "100%" }}
                        src={association.url}
                        alt="media item"
                        data-testid="attached-image"
                    />
                )}
            </div>
        </List.Item>
    );
};

export default MediaListItem;
