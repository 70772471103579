import { Alert, Col, DatePicker, Divider, Form, Input, Row, Select, Switch } from "antd";

import Editor from "@components/rich-text-editor";
import { MultilingualFormInput } from "@components/forms/multilingual/index";
import React from "react";

const { RangePicker } = DatePicker;

interface ItemsProps {
    items: MultilingualFormInput[];
    lang?: string;
}

const Items: React.FC<ItemsProps> = ({ items, lang }) => (
    <Form.Item noStyle>
        <Row gutter={12}>
            {items.map(
                ({
                    name,
                    rules,
                    type = "text",
                    isInvisible = false,
                    placeholder,
                    mode,
                    options,
                    alertMessage,
                    label,
                    grid = { col: { span: 24 } },
                    inputProps = {},
                    formItemProps = {}
                }: MultilingualFormInput) => {
                    return (
                        <Col {...grid.col} key={name}>
                            <Form.Item noStyle>
                                {type === "text" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        rules={rules}
                                        style={{ display: isInvisible ? "none" : "block" }}
                                    >
                                        <Input placeholder={placeholder} {...inputProps} />
                                    </Form.Item>
                                )}
                                {type === "email" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        rules={rules}
                                    >
                                        <Input
                                            placeholder={placeholder}
                                            type="email"
                                            {...inputProps}
                                        />
                                    </Form.Item>
                                )}
                                {type === "password" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        rules={rules}
                                        {...formItemProps}
                                    >
                                        <Input.Password placeholder={placeholder} {...inputProps} />
                                    </Form.Item>
                                )}
                                {type === "number" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        rules={rules}
                                    >
                                        <Input
                                            placeholder={placeholder}
                                            {...inputProps}
                                            type="number"
                                            pattern="[0-9]"
                                            max="1000000"
                                            min="0"
                                        />
                                    </Form.Item>
                                )}
                                {type === "select" && (
                                    <>
                                        <Form.Item
                                            label={lang ? `${label} [${lang}]` : label}
                                            name={lang ? [name, lang] : name}
                                            key={name}
                                            rules={rules}
                                            style={{ display: isInvisible ? "none" : "block" }}
                                        >
                                            <Select
                                                placeholder={placeholder}
                                                mode={mode}
                                                {...inputProps}
                                            >
                                                {options?.length &&
                                                    options.map(({ value, label }) => (
                                                        <Select.Option key={value} value={value}>
                                                            {label}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                        {alertMessage && (
                                            <>
                                                <Alert
                                                    style={{ marginTop: 5 }}
                                                    message={alertMessage}
                                                    type="warning"
                                                />
                                                <Divider />
                                            </>
                                        )}
                                    </>
                                )}
                                {type === "rich-text-editor" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        key={name}
                                        rules={rules}
                                    >
                                        <Editor placeholder={placeholder} {...inputProps} />
                                    </Form.Item>
                                )}
                                {type === "textarea" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        key={name}
                                        rules={rules}
                                    >
                                        <Input.TextArea placeholder={placeholder} {...inputProps} />
                                    </Form.Item>
                                )}
                                {type === "date-picker" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        key={name}
                                        rules={rules}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder={placeholder}
                                            {...inputProps}
                                        />
                                    </Form.Item>
                                )}
                                {type === "range-picker" && (
                                    <Form.Item
                                        label={lang ? `${label} [${lang}]` : label}
                                        name={lang ? [name, lang] : name}
                                        key={name}
                                        rules={rules}
                                    >
                                        <RangePicker style={{ width: "100%" }} {...inputProps} />
                                    </Form.Item>
                                )}
                                {type === "switch" && (
                                    <>
                                        <Form.Item
                                            label={lang ? `${label} [${lang}]` : label}
                                            name={lang ? [name, lang] : name}
                                            rules={rules}
                                            valuePropName="checked"
                                            style={{ display: isInvisible ? "none" : "block" }}
                                        >
                                            <Switch {...inputProps} />
                                        </Form.Item>
                                        {alertMessage && (
                                            <>
                                                <Alert
                                                    style={{ marginTop: 5 }}
                                                    message={alertMessage}
                                                    type="warning"
                                                />
                                                <Divider />
                                            </>
                                        )}
                                    </>
                                )}
                            </Form.Item>
                        </Col>
                    );
                }
            )}
        </Row>
    </Form.Item>
);

export default Items;
