import { Button, Input, List, Tag, Select } from "antd";
import { CSSStyle, CSSStyleKeyName } from "@store/surveys/types";
import React, { useEffect, useState } from "react";

import ColorPicker from "@components/ColorPicker";
import { useParams } from "react-router-dom";

interface CSSStylesListItemProps {
    item: CSSStyleKeyName;
    match: CSSStyle | null;
    isLoadingStyles: boolean;
    onUpdate: ({
        fieldname,
        fieldvalue
    }: {
        fieldname: string;
        fieldvalue: string | null;
    }) => Promise<any>;
}

const CSSStylesListItem: React.FC<CSSStylesListItemProps> = ({
    item,
    match,
    isLoadingStyles,
    onUpdate
}) => {
    const initialValue = match === null ? "" : match.fieldvalue;
    const [value, setValue] = useState<string | null>(initialValue);
    const [updateEnabled, setUpdateEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (value !== match?.fieldvalue) setUpdateEnabled(true);
        else setUpdateEnabled(false);
    }, [value, match]);

    useEffect(() => {
        if (match === null) return;
        setValue(match.fieldvalue || "");
    }, [match]);

    return (
        <List.Item
            actions={[
                <Button
                    disabled={!updateEnabled || isLoadingStyles}
                    type={updateEnabled && !isLoadingStyles ? "primary" : "default"}
                    onClick={async () =>
                        await onUpdate({
                            fieldname: item.keyname,
                            fieldvalue: value?.trim() || null
                        })
                    }
                    data-testid="update-style-button"
                >
                    Save
                </Button>
            ]}
            data-testid="css-style-listitem"
        >
            <List.Item.Meta
                title={item.description}
                // description={
                //     <a
                //         href="https://developer.mozilla.org/en-US/docs/Web/CSS/background-position"
                //         target="_blank"
                //     >
                //         Read MDN Docs
                //     </a>
                // }
            />
            <div style={{ width: "40%" }}>
                {item.type === "color" ? (
                    <div style={{ position: "relative", width: "fit-content" }}>
                        <ColorPicker color={value || "#fff"} onChangeComplete={setValue} />
                        {!!!value && (
                            <Tag
                                style={{
                                    position: "absolute",
                                    width: "calc(100% + 4px)",
                                    left: -2,
                                    top: -2,
                                    padding: "3px 10px",
                                    pointerEvents: "none"
                                }}
                            >
                                No color selected. Click to select
                            </Tag>
                        )}
                    </div>
                ) : item.type === "boolean" ? (
                    <Select
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                        style={{ width: 200 }}
                        options={[
                            {
                                value: "true",
                                label: "True"
                            },
                            {
                                value: "false",
                                label: "False"
                            }
                        ]}
                    />
                ) : (
                    <Input
                        type="text"
                        value={value || ""}
                        onChange={(e) => setValue(e.target.value)}
                        data-testid="css-style-input"
                    />
                )}
            </div>
        </List.Item>
    );
};

export default CSSStylesListItem;
