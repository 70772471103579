import { Alert, Button, Divider, Drawer, Space, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
    useAddSurveyQuestionOptionMutation,
    useGetSurveyQuestionQuery
} from "@store/surveys/surveys";
import { useNavigate, useParams } from "react-router";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual";
import { SurveyQuestionOptionFormItems } from "./types";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import { questionOptionInputs } from "./form/question-option-inputs";
import { InfoCircleFilled } from "@ant-design/icons";
import ColorPicker from "@components/ColorPicker";

const NewSurveyQuestionOption: React.FC = () => {
    const navigate = useNavigate();
    const { id, questionId } = useParams();
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = MultilingualForm.useForm();
    const [colorValue, setColorValue] = useState<string | null>(null);

    const [addSurveyQuestionOption, { isLoading: isLoadingAddSurveyQuestionOption }] =
        useAddSurveyQuestionOptionMutation();

    const {
        data: surveyQuestion,
        isLoading: isLoadingSurveyQuestion,
        isFetching: isFetchingSurveyQuestion,
        error: errorSurveyQuestion
    } = useGetSurveyQuestionQuery({ questionId });

    useEffect(() => {
        setVisible(true);
    }, []);

    const handleCloseDrawer = () => {
        setVisible(false);
        setTimeout(() => {
            navigate(`/survey/${id}/questions/${questionId}/options`);
        }, 400);
    };

    const handleOnSubmit = async (values: SurveyQuestionOptionFormItems) => {
        await performMutation({
            mutation: async () =>
                await addSurveyQuestionOption({
                    questionId,
                    ...values,
                    text_color: colorValue
                }).unwrap(),
            successMessage: "The survey question option was added successfully",
            errorMessage: "There was an error while creating the survey question option",
            onSuccessCallback: () => handleCloseDrawer(),
            formFieldsResetter: () => form.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            }
        });
    };

    const error = errorSurveyQuestion;
    const loading =
        isLoadingAddSurveyQuestionOption || isLoadingSurveyQuestion || isFetchingSurveyQuestion;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Drawer width={550} open={visible} onClose={handleCloseDrawer} title="Create new option">
            <Spin spinning={loading}>
                {surveyQuestion && (
                    <>
                        <MultilingualForm
                            inputs={questionOptionInputs({
                                isIcon: !!surveyQuestion?.is_icon,
                                isQuestionSlider: surveyQuestion.question_type === 1
                            })}
                            form={form}
                            onSubmit={handleOnSubmit}
                        />
                        {surveyQuestion.question_type === 1 && (
                            <>
                                <Space direction="vertical">
                                    <div className={"ant-form-item-label"}>Text color</div>
                                    <div style={{ position: "relative", width: "fit-content" }}>
                                        <ColorPicker
                                            color={colorValue || "#fff"}
                                            onChangeComplete={setColorValue}
                                        />
                                        {!!!colorValue && (
                                            <Tag
                                                style={{
                                                    position: "absolute",
                                                    width: "calc(100% + 4px)",
                                                    left: -2,
                                                    top: -2,
                                                    padding: "3px 10px",
                                                    pointerEvents: "none"
                                                }}
                                            >
                                                No color selected. Click to select
                                            </Tag>
                                        )}
                                    </div>
                                </Space>
                                <div style={{ paddingTop: 25 }} />
                            </>
                        )}
                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            data-testid="new-question-option-submit"
                            onClick={form.submit}
                        >
                            Create new survey question option
                        </Button>
                        <Divider />
                        <Alert
                            type="info"
                            message={
                                "You can attach a media element to an option only after the option has been created"
                            }
                            showIcon
                            icon={<InfoCircleFilled />}
                        />
                    </>
                )}
            </Spin>
        </Drawer>
    );
};

export default NewSurveyQuestionOption;
