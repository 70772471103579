import { Button, Col, Form, Input, Row, Typography } from "antd";

import { Error422 } from "@store/index";
import React from "react";
import { ResetPasswordFields } from "@store/admin/types";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import styles from "./Login.module.scss";
import { useNavigate } from "react-router";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useResetPasswordMutation } from "@store/admin/admin";

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const resetPasswordToken = useQueryParams("token");
    const email = useQueryParams("email");

    const [resetPassword, { isLoading: isLoadingResetPassword }] = useResetPasswordMutation();

    const handleSubmitForm = async (values: ResetPasswordFields) => {
        if (!resetPasswordToken || !email) navigate("/login");
        await performMutation({
            mutation: async () =>
                await resetPassword({
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                    email: email!,
                    token: resetPasswordToken!
                }).unwrap(),
            successMessage: "The password was reset successfully",
            errorMessage: "There was an error while resetting the password",
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            onSuccessCallback: () => navigate("/login")
        });
    };

    return (
        <div className={styles.login}>
            <Row className={styles.row}>
                <Col lg={8} md={6} sm={0} xl={8} xxl={9}>
                    <div className={styles.fakeHeight} />
                </Col>
                <Col lg={8} md={12} sm={24} xl={8} xxl={6} className={styles.col}>
                    <div className={styles.formCard}>
                        <Typography.Title level={3}>Reset your password</Typography.Title>
                        <div className={styles.form}>
                            <Form form={form} onFinish={handleSubmitForm} data-testid="login-form">
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your new password!"
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="new-password"
                                        placeholder="Input your new password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password_confirmation"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please confirm your new password!"
                                        },
                                        ({ getFieldValue }: any) => ({
                                            validator(_: any, value: any) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "The new password that you entered do not match!"
                                                    )
                                                );
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password
                                        autoComplete="new-password"
                                        placeholder="Confirm your new password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        loading={isLoadingResetPassword}
                                        className={styles.btn}
                                        type="primary"
                                    >
                                        Reset password
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col lg={8} md={6} sm={0} xl={8} xxl={9}>
                    <div className={styles.fakeHeight} />
                </Col>
            </Row>
        </div>
    );
};

export default Login;
