import { Col, Drawer, List, PageHeader, Row, Skeleton, Spin, Tabs } from "antd";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    useAttachSurveyMediasMutation,
    useDetachSurveyMediasMutation,
    useGetSurveyGalleryQuery,
    useGetSurveyMediaKeyNamesQuery,
    useGetSurveyMediasQuery
} from "@store/surveys/surveys";

import { BackgroundSignature } from "@components/MediaListItem/MediaListItem";
import ErrorMessage from "@components/ErrorMessage";
import FileUpload from "@components/FileUpload";
import GalleryListItem from "@components/GalleryListItem/GalleryListItem";
import MediaListItem from "@components/MediaListItem";
import { selectUser } from "@store/slices/user";
import { useSelector } from "react-redux";
import GalleryDrawer from "@components/GalleryDrawer/GalleryDrawer";

const FilesAndImages: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId } = useParams();
    const { identity } = useSelector(selectUser);

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [keyNameToUpload, setKeyNameToUpload] = useState<string | null>(null);
    const [imageBackground, setImageBackground] = useState<BackgroundSignature>({});

    useEffect(() => {
        if (keyNameToUpload !== null) setIsDrawerOpen(true);
    }, [keyNameToUpload]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null;
        if (isDrawerOpen === false) {
            timeout = setTimeout(() => {
                setKeyNameToUpload(null);
            }, 500);
        }
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [isDrawerOpen]);

    const {
        data: keyNames,
        isFetching: isFetchingKeyNames,
        isLoading: isLoadingKeyNames,
        error: errorMediaKeyNames
    } = useGetSurveyMediaKeyNamesQuery(surveyId!);

    const {
        data: medias,
        isFetching: isFetchingMedias,
        isLoading: isLoadingMedias,
        error: errorMedias
    } = useGetSurveyMediasQuery(surveyId!);

    const {
        data: gallery,
        isFetching: isFetchingGallery,
        isLoading: isLoadingGallery,
        error: errorGallery
    } = useGetSurveyGalleryQuery(surveyId!);

    const [detatchMedia, { isLoading: isLoadingDetachMedia, error: detatchMediaError }] =
        useDetachSurveyMediasMutation();
    const [attachMedia, { isLoading: isLoadingAttachMedia, error: attachMediaError }] =
        useAttachSurveyMediasMutation();

    const getDrawerTitle = () => {
        return (
            "Attach Media to: " +
                keyNames?.find((keyName) => keyName.keyname === keyNameToUpload)?.description || ""
        );
    };

    const error =
        errorMediaKeyNames || errorMedias || errorGallery || detatchMediaError || attachMediaError;
    const loading =
        isLoadingKeyNames ||
        isFetchingKeyNames ||
        isLoadingGallery ||
        isFetchingGallery ||
        isFetchingMedias ||
        isLoadingMedias ||
        isLoadingDetachMedia ||
        isLoadingAttachMedia;

    if (identity?.data && !identity?.data.is_media_tab_visible)
        return <Navigate to={`/survey/${surveyId}`} />;
    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader onBack={() => navigate(`/survey/${surveyId}`)} title={"Files and Images"} />
            <Row justify="center">
                <Col xs={23}>
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={keyNames}
                        renderItem={(item) => {
                            const association = medias?.find(
                                (media) => media.pivot.fieldname === item.keyname
                            );
                            return (
                                <MediaListItem
                                    item={item}
                                    association={association}
                                    imageBackground={imageBackground}
                                    setImageBackground={setImageBackground}
                                    setKeyNameToUpload={setKeyNameToUpload}
                                    detatchMedia={detatchMedia}
                                />
                            );
                        }}
                    />
                </Col>
            </Row>
            <GalleryDrawer
                title={getDrawerTitle()}
                setIsDrawerOpen={setIsDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                gallery={gallery}
                onAttachClick={async (itemId: string | number) => {
                    await attachMedia({
                        surveyId: surveyId!,
                        media_id: itemId,
                        fieldname: keyNameToUpload
                    });
                    setIsDrawerOpen(false);
                }}
                isLoadingAttachMedia={isLoadingAttachMedia}
            />
        </Spin>
    );
};

export default FilesAndImages;
