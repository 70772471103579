import { Button, Form, List, Space, Switch, Tooltip } from "antd";

import { GalleryItem } from "@store/surveys/types";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { sizeFormatter } from "human-readable";
import { useParams } from "react-router-dom";
import { useState } from "react";

interface GalleryListItemProps {
    item: GalleryItem;
    onAttachClick: Function;
}

const GalleryListItem: React.FC<GalleryListItemProps> = ({ item, onAttachClick }) => {
    const [background, setBackground] = useState<"white" | "black">("white");
    const { id: surveyId } = useParams();
    const defaults = sizeFormatter();

    return (
        <List.Item
            key={item.filename}
            extra={
                <div style={{ background: background }}>
                    <img width={272} src={item.url} alt="gallery item" />
                </div>
            }
        >
            <List.Item.Meta
                title={item.original_filename}
                description={"Size: " + defaults(parseInt(item.size))}
            />
            <Space direction="vertical">
                <Button onClick={() => onAttachClick(item.id)} type="primary">
                    Attach
                </Button>
                <Form.Item
                    label={
                        <Space direction="horizontal">
                            Background
                            <Tooltip
                                placement="top"
                                title={"Only for image preview purposes, will NOT modify the image"}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                    }
                >
                    <Switch
                        checkedChildren="white"
                        unCheckedChildren="black"
                        defaultChecked
                        size="default"
                        onChange={() =>
                            setBackground((ps) => {
                                if (ps === "white") return "black";
                                else return "white";
                            })
                        }
                    />
                </Form.Item>
            </Space>
        </List.Item>
    );
};

export default GalleryListItem;
