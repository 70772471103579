import { Button, Form, Modal, PageHeader, Space, Spin, Tag, Typography } from "antd";
import { FormConfigsInterface, enhancedQuestionInputs } from "@utils/profileFormConfigs";
import { Layout, Left, Right } from "@components/layout";
import { Link, Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    useDuplicateQuestionMutation,
    useGetSurveyQuery,
    useGetSurveyQuestionQuery,
    useGetSurveyQuestionsQuery,
    useGetSurveySlotsQuery,
    useUpdateQuestionsOrderMutation
} from "@store/surveys/surveys";
import { useNavigate, useParams } from "react-router";

import Card from "@components/Card/Card";
import { CopyOutlined } from "@ant-design/icons";
import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual/MultilingualForm";
import { SurveyQuestionType } from "@store/surveys/types";
import { isWithSlots } from "@utils/isWithSlots";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import moment from "moment";
import { performMutation } from "@utils/performMutation";
import { questionInputs } from "./form/new-question-inputs";
import { selectUser } from "@store/slices/user";
import { useSelector } from "react-redux";

const Questions: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId, questionId } = useParams();
    const { identity } = useSelector(selectUser);
    const [form] = Form.useForm();
    const [duplicateQuestionForm] = MultilingualForm.useForm();
    const [questionToDuplicate, setQuestionToDuplicate] = useState<string | null>(null);

    const [isQuestionType1Selected, setIsQuestionType1Selected] = useState<boolean>(false);
    const [isOpenQuestionSelected, setIsOpenQuestionSelected] = useState<boolean>(false);

    const [updateSurveyQuestionsOrder, { isLoading: isLoadingUpdateSurveyQuestionsOrder }] =
        useUpdateQuestionsOrderMutation();
    const [duplicateQuestion, { isLoading: isLoadingDuplicateQuestion }] =
        useDuplicateQuestionMutation();

    const {
        data: questions,
        isFetching: isFetchingSurveyQuestions,
        isLoading: isLoadingSurveyQuestions,
        error: errorSurveyQuestions
    } = useGetSurveyQuestionsQuery({ surveyId }, { skip: !!!surveyId });

    const {
        data: surveyQuestion,
        isFetching: isLoadingSurveyQuestion,
        isFetching: isFetchingSurveyQuestion,
        error: errorSurveyQuestion
    } = useGetSurveyQuestionQuery(
        { questionId: questionToDuplicate! },
        { skip: questionToDuplicate === null }
    );

    const {
        data: survey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        error: errorSurvey
    } = useGetSurveyQuery(surveyId!);

    const {
        data: futureSlots,
        isLoading: isLoadingSlots,
        isFetching: isFetchingSlots,
        error: errorSlots
    } = useGetSurveySlotsQuery(
        { surveyId: surveyId!, unpublishedOnly: true },
        { skip: !!!surveyId || survey === undefined || isWithSlots(survey?.profile) === false }
    );

    const handleSaveOrder = async (items: any) => {
        const order = items.map(({ id }: any) => id);

        await performMutation({
            mutation: async () => await updateSurveyQuestionsOrder({ surveyId, order }).unwrap(),
            successMessage: "The order of questions was saved successfully",
            errorMessage: "There was an error while saving the order of the questions"
        });
    };

    useEffect(() => {
        if (surveyQuestion && form && questionToDuplicate !== null) {
            duplicateQuestionForm.resetFields();

            const questionTitle =
                surveyQuestion.title === null
                    ? null
                    : Object.keys(surveyQuestion.title).reduce((prev, current) => {
                          return {
                              ...prev,
                              [current]: surveyQuestion.title?.[current] + " - duplicate"
                          };
                      }, {});

            const formattedQuestion = {
                ...surveyQuestion,
                title: questionTitle
            };

            duplicateQuestionForm.setFieldsValue({
                ...formattedQuestion
            });
            setIsQuestionType1Selected(formattedQuestion.question_type === 1);
            setIsOpenQuestionSelected(formattedQuestion.is_open === 1);
        }
    }, [surveyQuestion, questionId, form, questionToDuplicate]);

    const formInputs = identity?.data
        ? identity.data.is_admin
            ? questionInputs({
                  isMultipleSlotEnabled: isWithSlots(survey?.profile),
                  futureSlots: futureSlots || [],
                  isQuestionType1Selected: isQuestionType1Selected,
                  isOpenQuestionSelected: isOpenQuestionSelected
              })
            : enhancedQuestionInputs({
                  inputs: questionInputs({
                      isMultipleSlotEnabled: isWithSlots(survey?.profile),
                      futureSlots: futureSlots || [],
                      isQuestionType1Selected: isQuestionType1Selected,
                      isOpenQuestionSelected: isOpenQuestionSelected
                  }),
                  formConfigs: identity?.data.profile?.form_configs?.question as
                      | FormConfigsInterface
                      | null
                      | undefined,
                  canDisableTitle: false
              })
        : null;

    const handleDuplicateQuestion = async (values: SurveyQuestionType) => {
        await performMutation({
            mutation: async () =>
                await duplicateQuestion({
                    questionId: questionToDuplicate,
                    ...values
                }).unwrap(),
            onSuccessCallback: () => {
                navigate(`/survey/${surveyId}/questions`);
                setQuestionToDuplicate(null);
            },
            formFieldsResetter: () => duplicateQuestionForm.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                duplicateQuestionForm.setFields(valuesWithErrors);
            },
            successMessage: "The question was duplicated successfully",
            errorMessage: "There was an error while duplicating the question"
        });
    };

    const error = errorSurvey || errorSurveyQuestions || errorSurveyQuestion || errorSlots;
    const loading =
        isLoadingSurvey ||
        isFetchingSurvey ||
        isFetchingSurveyQuestions ||
        isLoadingSurveyQuestions ||
        isLoadingUpdateSurveyQuestionsOrder ||
        isLoadingSurveyQuestion ||
        isFetchingSurveyQuestion ||
        isLoadingSlots ||
        isFetchingSlots;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <>
            <PageHeader
                onBack={() => navigate(`/survey/${surveyId}`)}
                title={<Space direction="horizontal">Survey questions</Space>}
                extra={
                    isWithSlots(survey?.profile)
                        ? [
                              <Link key="question-groups" to={`/survey/${surveyId}/slots`}>
                                  <Button>Question groups</Button>
                              </Link>
                          ]
                        : []
                }
            />
            <Layout height="calc(100vh - 200px)">
                <Left
                    height="calc(100vh - 150px)"
                    canOrder
                    loadingItems={loading}
                    onSaveOrder={handleSaveOrder}
                    action={
                        <Link
                            to={`/survey/${surveyId}/questions/new`}
                            data-testid="new-question-button"
                            style={{
                                display: identity?.data.is_new_questions_visible ? "block" : "none"
                            }}
                            aria-hidden={identity?.data.is_new_questions_visible ? "false" : "true"}
                        >
                            <Button type="primary" size="small">
                                + New
                            </Button>
                        </Link>
                    }
                    title="Questions"
                    items={questions}
                    renderItem={(question) => (
                        <Spin key={question.id} spinning={false}>
                            <Link to={`/survey/${surveyId}/questions/${question.id}`}>
                                <Card
                                    published={
                                        question.created_at
                                            ? moment(question.created_at).fromNow()
                                            : undefined
                                    }
                                    updated={
                                        question.updated_at
                                            ? moment(question.updated_at).fromNow()
                                            : undefined
                                    }
                                    title={question.title?.it || question.title?.en}
                                    selected={surveyId !== undefined && question.id === questionId}
                                    duplicateElement={
                                        identity?.data.is_new_questions_visible ? (
                                            <CopyOutlined
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setQuestionToDuplicate(question.id);
                                                }}
                                            />
                                        ) : undefined
                                    }
                                >
                                    {!isWithSlots(survey?.profile) && (
                                        <Typography.Text>
                                            Is published:{" "}
                                            <Tag color={question.is_published ? "green" : "red"}>
                                                {question.is_published ? "Yes" : "No"}
                                            </Tag>
                                        </Typography.Text>
                                    )}
                                </Card>
                            </Link>
                        </Spin>
                    )}
                />
                <Right height="calc(100vh - 150px)">
                    <Outlet />
                </Right>
            </Layout>
            <Modal
                open={questionToDuplicate !== null}
                title="Duplicate question"
                onCancel={() => setQuestionToDuplicate(null)}
                width={"80%"}
                onOk={duplicateQuestionForm.submit}
                okText={"Duplicate question"}
                confirmLoading={isLoadingDuplicateQuestion}
                afterClose={() => duplicateQuestionForm.resetFields()}
            >
                <MultilingualForm
                    inputs={Array.isArray(formInputs) ? formInputs : []}
                    form={duplicateQuestionForm}
                    onValuesChange={(_, values) => {
                        setIsQuestionType1Selected(values?.question_type === 1);
                        setIsOpenQuestionSelected(values.is_open === true);
                    }}
                    onSubmit={handleDuplicateQuestion}
                />
            </Modal>
        </>
    );
};

export default Questions;
