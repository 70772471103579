import React from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Result, Space, Typography } from "antd";

interface ErrorMessageProps {
    message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
    return (
        <Result
            status="error"
            title={"Error"}
            subTitle={message}
            extra={[
                <Space direction="vertical">
                    <Typography.Text type="secondary">
                        Please contact support if the error persists.
                    </Typography.Text>
                    <Divider />
                    <Button type="primary">
                        <Link to="/">Go to the homepage</Link>
                    </Button>
                </Space>
            ]}
        ></Result>
    );
};

export default ErrorMessage;
