import {
    AppstoreOutlined,
    ExportOutlined,
    UnorderedListOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import { Menu } from "antd";
import React from "react";
import styles from "./TopMenu.module.scss";

const menuItems = [
    {
        label: <Link to="/">Surveys</Link>,
        key: "survey",
        icon: <AppstoreOutlined />
    },
    {
        label: <Link to="/profiles">Profiles</Link>,
        key: "profiles",
        icon: <UserOutlined />
    },
    {
        label: <Link to="/logs">Logs</Link>,
        key: "logs",
        icon: <UnorderedListOutlined />
    },
    {
        label: <Link to="/metrics">Metrics</Link>,
        key: "metrics",
        icon: <ExportOutlined />
    }
];

const TopMenu: React.FC<any> = () => {
    const location = useLocation();

    const activeRoute = location.pathname.split("/")[1] || "survey";

    return (
        <div className={styles["top-menu"]}>
            <Menu
                mode="horizontal"
                selectedKeys={[activeRoute]}
                items={menuItems}
                style={{ minWidth: "800px" }}
                // overflowedIndicator={null}
            ></Menu>
        </div>
    );
};

export default TopMenu;
