import { Button, Divider, Modal, Popconfirm, Popover, Space, Tag, Typography } from "antd";
import { CopyOutlined, DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormConfigsInterface, enhancedQuestionInputs } from "@utils/profileFormConfigs";
import { Layout, Left, Right } from "@components/layout";
import { Link, Outlet } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import {
    useDeleteSurveyMutation,
    useDuplicateSurveyMutation,
    useGetSurveyQuery,
    useGetSurveysQuery,
    useGetTimezonesQuery
} from "@store/surveys/surveys";
import { useNavigate, useParams } from "react-router";

import Card from "@components/Card/Card";
import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual/MultilingualForm";
import { SurveyType } from "@store/surveys/types";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import moment from "moment";
import { performMutation } from "@utils/performMutation";
import { selectUser } from "@store/slices/user";
import { surveyInputs } from "./form/new-survey-inputs";
import { useCanCreateSurvey } from "src/hooks/useCanCreateSurvey";
import { useCheckSlug } from "src/hooks/useCheckSlug";
import { useDebouncedSearchParam } from "src/hooks/useDebouncedSearchParam";
import { useGetProfilesQuery } from "@store/profiles/profiles";
import { useSelector } from "react-redux";

const Surveys = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { identity } = useSelector(selectUser);
    const [duplicateSurveyForm] = MultilingualForm.useForm();
    const [surveyToDuplicate, setSurveyToDuplicate] = useState<string | null>(null);
    const [currentProfileId, setCurrentProfileId] = useState<number | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const { searchParam, setSearchParam, debouncedSearchParam } = useDebouncedSearchParam();
    useEffect(() => {
        setCurrentPage(1);
    }, [searchParam]);

    const { cannotCreateSurvey, errorProfileData, refetchProfileData, availableSurveys } =
        useCanCreateSurvey(identity?.data.profile?.id);

    const {
        data: surveysData,
        isFetching: isFetchingSurveysData,
        isLoading: isLoadingSurveysData,
        error: errorSurveysData
    } = useGetSurveysQuery({ page: currentPage, searchParam: debouncedSearchParam });

    const {
        data: timezones,
        isFetching: isFetchingTimezones,
        isLoading: isLoadingTimezones,
        error: errorTimezones
    } = useGetTimezonesQuery("");

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData
    } = useGetProfilesQuery(
        { step: undefined },
        { skip: identity === undefined || !identity?.data?.is_admin }
    );

    const {
        data: survey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        error: errorSurvey
    } = useGetSurveyQuery(surveyToDuplicate!, { skip: surveyToDuplicate === null });

    const [deleteSurvey, { isLoading: isLoadingDeleteSurvey }] = useDeleteSurveyMutation();
    const [duplicateSurvey, { isLoading: isLoadingDuplicateSurvey }] = useDuplicateSurveyMutation();

    const handleDeleteSurvey = async (pid: string) => {
        await performMutation({
            mutation: async () => await deleteSurvey(pid).unwrap(),
            onSuccessCallback: () => navigate("/"),
            successMessage: "The survey was deleted successfully",
            errorMessage: "There was an error while deleting the survey"
        });
    };

    useEffect(() => {
        try {
            !identity?.data?.is_admin && refetchProfileData();
        } catch (e) {
            console.warn("Cannot refetch profile data");
        }
    }, [surveysData?.surveys]);

    const handleDuplicateSurvey = async (values: SurveyType) => {
        const openAtDate = timeZoneToUtc({ time: values.open_at, zone: values.timezone! });
        const closeAtDate = timeZoneToUtc({ time: values.close_at, zone: values.timezone! });

        await performMutation({
            mutation: async () =>
                await duplicateSurvey({
                    surveyId: surveyToDuplicate,
                    ...values,
                    slug: values.slug || null,
                    profile_id: currentProfileId,
                    open_at: openAtDate,
                    close_at: closeAtDate
                }).unwrap(),
            onSuccessCallback: () => {
                navigate("/");
                setSurveyToDuplicate(null);
            },
            formFieldsResetter: () => duplicateSurveyForm.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                duplicateSurveyForm.setFields(valuesWithErrors);
            },
            successMessage: "The survey was duplicated successfully",
            errorMessage: "There was an error while duplicating the survey"
        });
    };

    useEffect(() => {
        if (survey) setCurrentProfileId(survey.profile_id);
    }, [survey]);

    const { validSlug, setSlugForValidation } = useCheckSlug({
        formInstance: duplicateSurveyForm,
        slugFromSurvey: (survey?.slug || "survey") + "-duplicate",
        surveyId: id,
        profileId: currentProfileId
    });

    useEffect(() => {
        if (survey && surveyToDuplicate !== null) {
            duplicateSurveyForm.resetFields();

            const openConverted = utcToTimeZone({ time: survey.open_at, zone: survey.timezone });
            const closeConverted = utcToTimeZone({ time: survey.close_at, zone: survey.timezone });

            const surveyTitle =
                survey.title === null
                    ? null
                    : Object.keys(survey.title).reduce((prev, current) => {
                          return { ...prev, [current]: survey.title?.[current] + " - duplicate" };
                      }, {});

            const formattedSurvey = {
                ...survey,
                title: surveyTitle,
                slug: (survey.slug || "survey") + "-duplicate"
            };

            duplicateSurveyForm.setFieldsValue({
                ...formattedSurvey,
                close_at: closeConverted ? moment(closeConverted) : undefined,
                open_at: openConverted ? moment(openConverted) : undefined,
                is_active: !!survey.is_active,
                profile_id: survey.profile_id ? String(survey.profile_id) : null
            });
        }
    }, [duplicateSurveyForm, survey, surveyToDuplicate]);

    const inputList = surveyInputs({
        timezones,
        slugPath: validSlug,
        isAdmin: identity?.data?.is_admin,
        profiles: profilesData?.profiles,
        profileId: currentProfileId,
        identity: identity
    });

    const formInputs =
        timezones && identity?.data
            ? identity.data.is_admin
                ? inputList
                : enhancedQuestionInputs({
                      inputs: inputList,
                      formConfigs: identity?.data.profile?.form_configs?.survey as
                          | FormConfigsInterface
                          | null
                          | undefined,
                      canDisableTitle: false
                  })
            : null;

    const error =
        errorSurveysData || errorTimezones || errorSurvey || errorProfilesData || errorProfileData;
    const loading =
        isFetchingSurveysData ||
        isLoadingSurveysData ||
        isLoadingDeleteSurvey ||
        isFetchingTimezones ||
        isLoadingTimezones ||
        isLoadingSurvey ||
        isFetchingSurvey ||
        isLoadingDuplicateSurvey ||
        isFetchingProfilesData ||
        isLoadingProfilesData;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Layout>
            <Left
                action={
                    <Space direction="horizontal">
                        {availableSurveys !== null && (
                            <Popover
                                content={
                                    <>
                                        Surveys available to be created during this licence period:{" "}
                                        {availableSurveys}
                                    </>
                                }
                            >
                                <Button size="small" icon={<InfoCircleOutlined />}></Button>
                            </Popover>
                        )}
                        {!cannotCreateSurvey ? (
                            <Link to="/survey/new">
                                <Button type="primary" size="small">
                                    + New
                                </Button>
                            </Link>
                        ) : (
                            <Popover
                                title="Survey limit reached"
                                content={<>You cannot create a new survey at this time</>}
                            >
                                <Button type="primary" size="small" disabled>
                                    + New
                                </Button>
                            </Popover>
                        )}
                    </Space>
                }
                onSearch={setSearchParam}
                searchValue={searchParam}
                title="Surveys"
                loadingItems={loading}
                items={surveysData?.surveys || []}
                pagination={surveysData?.pagination}
                onPageChange={(page) => setCurrentPage(page)}
                renderItem={(survey: SurveyType) => (
                    <Link to={`/survey/${survey.id}`} data-testid="survey-card">
                        <Card
                            published={
                                survey.created_at ? moment(survey.created_at).fromNow() : undefined
                            }
                            updated={
                                survey.updated_at ? moment(survey.updated_at).fromNow() : undefined
                            }
                            title={survey.title?.it || survey.title?.en || ""}
                            selected={id !== undefined && survey.id === id}
                            deleteElement={
                                identity?.data.is_admin ? (
                                    <div onClick={(e: SyntheticEvent) => e.stopPropagation()}>
                                        <Popconfirm
                                            title="Are you sure you want to delete this survey?"
                                            okText="Delete"
                                            onConfirm={() => handleDeleteSurvey(survey.id)}
                                        >
                                            <DeleteOutlined />
                                        </Popconfirm>
                                    </div>
                                ) : undefined
                            }
                            duplicateElement={
                                !cannotCreateSurvey ? (
                                    <CopyOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setSurveyToDuplicate(survey.id);
                                        }}
                                    />
                                ) : undefined
                            }
                        >
                            <Typography.Text type="secondary">
                                Active:{" "}
                                {survey.is_active ? (
                                    <Tag color="success">Yes</Tag>
                                ) : (
                                    <Tag color="error">No</Tag>
                                )}
                            </Typography.Text>
                            <Divider style={{ margin: "7px 2px" }} />
                            {survey.owner_name && (
                                <>
                                    <div>
                                        <Typography.Text type="secondary">
                                            Owner: <Tag>{survey.owner_name}</Tag>
                                        </Typography.Text>
                                    </div>
                                    <Divider style={{ margin: "7px 2px" }} />
                                </>
                            )}
                            {identity?.data?.is_admin && survey.creator_name && (
                                <>
                                    <div>
                                        <Typography.Text type="secondary">
                                            Creator: <Tag>{survey.creator_name}</Tag>
                                        </Typography.Text>
                                    </div>
                                    <Divider style={{ margin: "7px 2px" }} />
                                </>
                            )}
                        </Card>
                    </Link>
                )}
            />
            <Right>
                <Outlet />
            </Right>
            <Modal
                open={surveyToDuplicate !== null}
                title="Duplicate survey"
                onCancel={() => setSurveyToDuplicate(null)}
                width={"80%"}
                onOk={!cannotCreateSurvey ? duplicateSurveyForm.submit : undefined}
                okText={"Duplicate survey"}
                confirmLoading={isLoadingDuplicateSurvey}
                afterClose={() => duplicateSurveyForm.resetFields()}
            >
                <MultilingualForm
                    inputs={Array.isArray(formInputs) ? formInputs : []}
                    form={duplicateSurveyForm}
                    onSubmit={handleDuplicateSurvey}
                    onValuesChange={(changedValues: any, values: any) => {
                        if (changedValues.slug) {
                            setSlugForValidation(changedValues.slug);
                        }
                        if (changedValues.profile_id) {
                            setCurrentProfileId(parseInt(changedValues.profile_id));
                            setSlugForValidation(values.slug);
                        }
                    }}
                />
            </Modal>
        </Layout>
    );
};

export default Surveys;
