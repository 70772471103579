import type { AxiosError, AxiosRequestConfig } from "axios";

import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { BaseQueryResult } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { PaginatedLogsType } from "@store/logs/types";
import axios from "axios";
import { createApi } from "@reduxjs/toolkit/query/react";

const axiosBaseQuery =
    (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError> =>
    async ({ url, method, data, params }) => {
        try {
            axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL + "/admin";
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
                "token"
            )}`;
            const result = await axios({
                url,
                method,
                data,
                params
            });
            return { data: result.data };
        } catch (axiosError) {
            const error = axiosError as AxiosError;
            return {
                error
            };
        }
    };

const LOGS = "Logs";

const logsApi = createApi({
    reducerPath: "logs",
    tagTypes: [LOGS],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        getLogs: build.query<
            PaginatedLogsType,
            {
                page?: number;
                causer_type?: string;
                causer_id?: string;
                subject_type?: string;
                subject_id?: string;
                date_from?: string;
                date_to?: string;
            }
        >({
            query: ({ page = 1, ...body }) => {
                return {
                    url: `/activity-logs?page=${page}`,
                    method: "GET",
                    params: body
                };
            },
            transformResponse: (res: BaseQueryResult<any>): any => ({
                logs: res.data,
                pagination: {
                    total: res.meta.total,
                    perPage: res.meta.per_page,
                    current: res.meta.current_page
                }
            }),
            providesTags: () => [LOGS]
        })
    })
});

export default logsApi;

export const { useGetLogsQuery } = logsApi;
