import { Button, Col, PageHeader, Row, Spin } from "antd";
import { FormConfigsInterface, enhancedQuestionInputs } from "@utils/profileFormConfigs";
import React, { useEffect, useState } from "react";
import {
    useGetSurveyQuery,
    useGetSurveyQuestionQuery,
    useGetSurveySlotsQuery,
    useUpdateSurveyQuestionMutation
} from "@store/surveys/surveys";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Link } from "react-router-dom";
import MultilingualForm from "@components/forms/multilingual";
import { SurveyQuestionFormItems } from "./types";
import { isWithSlots } from "@utils/isWithSlots";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import { questionInputs } from "./form/new-question-inputs";
import { selectUser } from "@store/slices/user";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const UpdateQuestion: React.FC = () => {
    const { id: surveyId, questionId } = useParams();
    const [form] = MultilingualForm.useForm();
    const { identity } = useSelector(selectUser);

    const [isQuestionType1Selected, setIsQuestionType1Selected] = useState<boolean>(false);
    const [isOpenQuestionSelected, setIsOpenQuestionSelected] = useState<boolean>(false);

    const {
        data: surveyQuestion,
        isFetching: isLoadingSurveyQuestion,
        isFetching: isFetchingSurveyQuestion,
        error: errorSurveyQuestion
    } = useGetSurveyQuestionQuery({ questionId }, { skip: !!!questionId });

    const {
        data: survey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        error: errorSurvey
    } = useGetSurveyQuery(surveyId!);

    const {
        data: futureSlots,
        isLoading: isLoadingSlots,
        isFetching: isFetchingSlots,
        error: errorSlots
    } = useGetSurveySlotsQuery(
        { surveyId: surveyId!, unpublishedOnly: true },
        { skip: !!!surveyId || survey === undefined || isWithSlots(survey?.profile) === false }
    );

    const [updateSurveyQuestion, { isLoading: isLoadingUpdateSurveyQuestion }] =
        useUpdateSurveyQuestionMutation();

    useEffect(() => {
        if (surveyQuestion && form) {
            form.resetFields();
            form.setFieldsValue({ ...surveyQuestion });

            setIsQuestionType1Selected(surveyQuestion.question_type === 1);
            setIsOpenQuestionSelected(surveyQuestion.is_open === 1);
        }
    }, [surveyQuestion, questionId, form]);

    const handleOnSubmit = async (values: SurveyQuestionFormItems) => {
        await performMutation({
            mutation: async () =>
                await updateSurveyQuestion({
                    questionId,
                    ...values
                }).unwrap(),
            successMessage: "The survey question was updated successfully",
            errorMessage: "There was an error while updating the survey question",
            formFieldsResetter: () => form.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            }
        });
    };

    const formInputs = identity?.data
        ? identity.data.is_admin
            ? questionInputs({
                  isMultipleSlotEnabled: isWithSlots(survey?.profile),
                  futureSlots: futureSlots || [],
                  isQuestionType1Selected: isQuestionType1Selected,
                  isOpenQuestionSelected: isOpenQuestionSelected
              })
            : enhancedQuestionInputs({
                  inputs: questionInputs({
                      isMultipleSlotEnabled: isWithSlots(survey?.profile),
                      futureSlots: futureSlots || [],
                      isQuestionType1Selected: isQuestionType1Selected,
                      isOpenQuestionSelected: isOpenQuestionSelected
                  }),
                  formConfigs: identity?.data.profile?.form_configs?.question as
                      | FormConfigsInterface
                      | null
                      | undefined,
                  canDisableTitle: true
              })
        : null;

    const error = errorSurvey || errorSurveyQuestion || errorSlots;
    const loading =
        isLoadingSurvey ||
        isFetchingSurvey ||
        isLoadingSurveyQuestion ||
        isFetchingSurveyQuestion ||
        isLoadingUpdateSurveyQuestion ||
        isLoadingSlots ||
        isFetchingSlots;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader
                title="Survey question"
                extra={
                    surveyQuestion?.is_open
                        ? []
                        : [
                              <Link
                                  key="link"
                                  to={`/survey/${surveyId}/questions/${questionId}/options`}
                              >
                                  <Button>Options</Button>
                              </Link>
                          ]
                }
            />
            <Row justify="center">
                <Col xs={23}>
                    <MultilingualForm
                        form={form}
                        inputs={
                            Array.isArray(formInputs)
                                ? formInputs.map((input) => {
                                      if (input.name === "is_open") {
                                          return {
                                              ...input,
                                              inputProps: { ...input.inputProps, disabled: true }
                                          };
                                      } else return input;
                                  })
                                : []
                        }
                        onValuesChange={(_, values) => {
                            setIsQuestionType1Selected(values?.question_type === 1);
                        }}
                        onSubmit={handleOnSubmit}
                        actions={
                            formInputs?.length
                                ? [
                                      <Button key="submit" type="primary" htmlType="submit">
                                          Update survey question
                                      </Button>
                                  ]
                                : undefined
                        }
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default UpdateQuestion;
