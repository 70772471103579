import { LinkOutlined, QrcodeOutlined } from "@ant-design/icons";

import { IdentityType } from "@store/slices/user";
import { MultilingualFormInput } from "@components/forms/multilingual";
import { ProfileType } from "@store/profiles/types";
import QRCodeGenerator from "@components/QRCodeGenerator";
import { SelectOption } from "@components/forms/multilingual";
import { Space } from "antd";
import { forceAdminNotRequiredFields } from "@utils/surveyFormConfig";
import inputs from "./intputs";

interface Timezone {
    id: string;
}

const mapTimezonesToOptions = (timezones: Timezone[]): SelectOption[] => {
    return timezones?.map((timezone: Timezone) => ({ label: timezone.id, value: timezone.id }));
};

const surveyInputs = ({
    timezones,
    slugPath,
    isAdmin,
    profiles,
    profileId,
    identity
}: {
    timezones: Timezone[];
    isAdmin: boolean | undefined;
    slugPath: string | null;
    profiles: ProfileType[] | undefined;
    profileId: number | null;
    identity: IdentityType | undefined;
}): MultilingualFormInput[] => {
    let formInputs: MultilingualFormInput[] = [
        {
            name: "owner_name",
            label: "Owner (leave blank to set your email)",
            placeholder: identity?.data.email || "Add owner email address",
            multilingual: false
        },
        {
            name: "program_code",
            label: "Program Code (CAW Number)",
            multilingual: false,
            rules: [{ required: true, message: "Please insert a Program Code (CAW Number)" }]
        },
        ...inputs,
        {
            name: "closed_message",
            label: "Survey closed message",
            type: "rich-text-editor"
        },
        {
            name: "pre_message",
            label: "Pre survey start message",
            type: "rich-text-editor"
        },
        {
            name: "post_message",
            label: "Post survey finished message",
            type: "rich-text-editor"
        },
        {
            name: "description",
            label: "Survey legend (a text that is visible below the subtitle when the survey is unanswered)",
            type: "rich-text-editor"
        },
        {
            name: "answered_feedback_message",
            label: "Thank you for answering message",
            type: "text"
        },
        {
            name: "required_message",
            label: "Required text helper: the explicatory text of the * that marks required questions",
            type: "text"
        },
        {
            name: "submit_message",
            label: "Text of the submit button of the survey",
            type: "text"
        },
        {
            name: "timezone",
            label: "Timezone",
            type: "select",
            multilingual: false,
            options: mapTimezonesToOptions(timezones),
            grid: { col: { span: 24 } },
            inputProps: {
                showSearch: true
            }
        },
        {
            name: "open_at",
            label: "Open at",
            type: "date-picker",
            multilingual: false,
            rules: isAdmin ? [] : [{ required: true, message: "Please insert an 'open at' date" }],
            inputProps: {
                showTime: { format: "HH:mm" },
                format: "DD-MM-YYYY HH:mm",
                allowClear: true
            },
            grid: { col: { span: 12 } }
        },
        {
            name: "close_at",
            label: "Closed at",
            type: "date-picker",
            multilingual: false,
            rules: isAdmin ? [] : [{ required: true, message: "Please insert a 'closed at' date" }],
            inputProps: {
                showTime: { format: "HH:mm" },
                format: "DD-MM-YYYY HH:mm",
                allowClear: true
            },
            grid: { col: { span: 12 } }
        },
        {
            name: "is_active",
            label: "Is active",
            type: "switch",
            multilingual: false
        }
    ];
    if (isAdmin) {
        formInputs = formInputs.map((input: MultilingualFormInput) => {
            if (forceAdminNotRequiredFields.includes(input.name)) {
                return {
                    ...input,
                    rules: undefined
                };
            }
            return input;
        });
    }
    formInputs.map((input) => {
        if (input.name === "title")
            return {
                ...input,
                label:
                    typeof input.label === "string" && profileId === null
                        ? input.label + " (Select a profile ID to activate)"
                        : input.label,
                inputProps: input.inputProps
                    ? {
                          ...input.inputProps,
                          disabled: profileId === null ? true : false
                      }
                    : {
                          disabled: profileId === null ? true : false
                      }
            };
        else return input;
    });

    let url = !isAdmin
        ? identity?.data.profile?.domain?.value
        : profiles?.find((profile) => profile.id === profileId)?.domain?.value;
    if (url && url.substr(-1) !== "/") url += "/";

    const previewUrl = ["https://", url, slugPath].join("");

    formInputs.splice(3, 0, {
        name: "slug",
        label:
            "Slug (minimum 8 characters)" +
            (profileId === null ? " (Select a profile ID to activate)" : ""),
        type: "text",
        multilingual: false,
        inputProps: !identity
            ? {}
            : {
                  disabled: profileId === null ? true : false,
                  addonBefore: url,
                  addonAfter:
                      slugPath === null ? undefined : (
                          <Space direction="horizontal">
                              <QRCodeGenerator baseUrl={previewUrl}>
                                  <Space direction="horizontal">
                                      <QrcodeOutlined />
                                      Download QR code
                                  </Space>
                              </QRCodeGenerator>
                              <a
                                  style={{ height: 30, padding: "0 10px" }}
                                  href={previewUrl}
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                  <Space direction="horizontal">
                                      <LinkOutlined />
                                      Preview
                                  </Space>
                              </a>
                          </Space>
                      )
              },
        rules: [{ required: isAdmin ? false : true, message: "Please insert a slug" }]
    });

    if (isAdmin)
        formInputs.unshift({
            name: "profile_id",
            label: "Profile ID",
            type: "select",
            multilingual: false,
            rules: [{ required: true, message: "Please select a profile ID" }],
            options: mapProfilesToOptions(profiles)
        });
    return formInputs as MultilingualFormInput[];
};

const mapProfilesToOptions = (profiles: ProfileType[] | undefined) => {
    if (!profiles) return undefined;
    return profiles.map((profile) => {
        return {
            label: profile.name,
            value: String(profile.id)
        };
    });
};

export { surveyInputs };

export default surveyInputs;
