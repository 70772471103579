import { Button, Space } from "antd";

import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import styles from "./NoSelected.module.scss";

export interface NoSelectedProps {
    title: string;
    buttonText?: string;
    link?: string;
}

const NoSelected: React.FC<NoSelectedProps> = ({ link, buttonText, title }) => {
    return (
        <div className={styles.card}>
            <div className={styles.image}>
                <img src="/not-found.png" alt="" />
            </div>
            <div className={styles.createNew}>
                <Space direction="horizontal">
                    {`${title} `}
                    {link && buttonText && (
                        <Link to={link}>
                            <Button size="small" icon={<PlusOutlined />} type="primary">
                                {buttonText}
                            </Button>
                        </Link>
                    )}
                </Space>
            </div>
        </div>
    );
};

export default NoSelected;
