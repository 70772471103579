import {
    Button,
    Col,
    List,
    PageHeader,
    Popconfirm,
    Row,
    Space,
    Tag,
    Tooltip,
    Typography
} from "antd";
import {
    DeleteOutlined,
    DownCircleOutlined,
    EditOutlined,
    PlusOutlined,
    SortAscendingOutlined,
    UpCircleOutlined
} from "@ant-design/icons";
import { Link, Outlet } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import {
    useAttachOptionMediaMutation,
    useDeleteSurveyQuestionOptionMutation,
    useDetachOptionMediaMutation,
    useGetSurveyGalleryQuery,
    useGetSurveyQuestionOptionsQuery,
    useGetSurveyQuestionQuery,
    useUpdateOptionsOrderMutation
} from "@store/surveys/surveys";
import { useNavigate, useParams } from "react-router";

import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import GalleryDrawer from "@components/GalleryDrawer";
import { SurveyQuestionOptionType } from "@store/surveys/types";
import { performMutation } from "@utils/performMutation";
import { selectUser } from "@store/slices/user";
import { useSelector } from "react-redux";

const QuestionOptions: React.FC = () => {
    const navigate = useNavigate();
    const { id: surveyId, questionId } = useParams();
    const { identity } = useSelector(selectUser);
    const [order, toggleOrder] = useState<boolean>(false);
    const [items, setItems] = useState<any>([]);
    const [needToSaveOrder, toggleNeedToSaveOrder] = useState<boolean>(false);
    const [optionIdForMedia, setOptionIdForMedia] = useState<string | null>(null);

    const {
        data: surveyQuestionOptions,
        isFetching: isFetchingSurveyQuestionOptions,
        isLoading: isLoadingSurveyQuestionOptions,
        error: errorSurveyQuestionOptions,
        refetch: refetchOptions
    } = useGetSurveyQuestionOptionsQuery({ questionId }, { skip: !!!questionId });

    const {
        data: surveyQuestion,
        isLoading: isLoadingSurveyQuestion,
        isFetching: isFetchingSurveyQuestion,
        error: errorSurveyQuestion
    } = useGetSurveyQuestionQuery({ questionId });

    const {
        data: gallery,
        isFetching: isFetchingGallery,
        isLoading: isLoadingGallery,
        error: errorGallery
    } = useGetSurveyGalleryQuery(surveyId!);

    const [deleteOption, { isLoading: isLoadingDeleteOption }] =
        useDeleteSurveyQuestionOptionMutation();
    const [updateOptionsOrder, { isLoading: isLoadingUpdateOptionsOrder }] =
        useUpdateOptionsOrderMutation();
    const [attachMedia, { isLoading: isLoadingAttachMedia }] = useAttachOptionMediaMutation();
    const [detachMedia, { isLoading: isLoadingDetachMedia }] = useDetachOptionMediaMutation();

    const getDefaultItemsState = useCallback(() => {
        setItems(surveyQuestionOptions);
    }, [surveyQuestionOptions]);

    const handleDeleteOption = async (id: string) => {
        await performMutation({
            mutation: async () => await deleteOption(id).unwrap(),
            successMessage: "The question option was deleted successfully",
            errorMessage: "There was an error while deleting the question options"
        });
    };

    const handleToggleOrder = () => {
        if (order && needToSaveOrder) {
            toggleNeedToSaveOrder(false);
            getDefaultItemsState();
        }

        toggleOrder(!order);
    };
    const handlePutUp = (i: number) => {
        const _items = [...items];
        const temp = _items[i];
        _items[i] = _items[i - 1];
        _items[i - 1] = temp;
        setItems(_items);
        toggleNeedToSaveOrder(true);
    };
    const handlePutDown = (i: number) => {
        const _items = [...items];
        const temp = _items[i];
        _items[i] = _items[i + 1];
        _items[i + 1] = temp;
        setItems(_items);
        toggleNeedToSaveOrder(true);
    };
    const handleSaveOrder = async () => {
        const order = items.map(({ id }: any) => id);

        await performMutation({
            mutation: async () => await updateOptionsOrder({ questionId, order }).unwrap(),
            successMessage: "The order of the question options was updated successfully",
            errorMessage: "There was an error while updating the order of the question options",
            onSuccessCallback: () => {
                toggleNeedToSaveOrder(false);
                toggleOrder(false);
            }
        });
    };

    useEffect(() => {
        if (surveyQuestionOptions) {
            getDefaultItemsState();
        }
    }, [surveyQuestionOptions, getDefaultItemsState]);

    const error = errorSurveyQuestionOptions || errorGallery || errorSurveyQuestion;
    const loading =
        isFetchingSurveyQuestionOptions ||
        isLoadingSurveyQuestionOptions ||
        isLoadingDeleteOption ||
        isLoadingUpdateOptionsOrder ||
        isLoadingSurveyQuestion ||
        isFetchingSurveyQuestion ||
        isLoadingDetachMedia ||
        isFetchingGallery ||
        isLoadingGallery;

    if (error) return <ErrorMessage message="There was a message, please try again later" />;
    return (
        <div>
            <PageHeader
                onBack={() => navigate(`/survey/${surveyId}/questions/${questionId}`)}
                title="Survey question options"
            />
            <Row justify="center">
                <Col xs={23}>
                    <List
                        loading={loading}
                        size="small"
                        bordered
                        header={
                            <Row justify="center">
                                <Col xs={24}>
                                    <Typography.Title level={4}>Options</Typography.Title>
                                    <Space direction="horizontal">
                                        {!order && (
                                            <Link
                                                to={`/survey/${surveyId}/questions/${questionId}/options/new`}
                                            >
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    size="small"
                                                    type="primary"
                                                >
                                                    Add new
                                                </Button>
                                            </Link>
                                        )}
                                        {needToSaveOrder && (
                                            <Button size="small" onClick={handleSaveOrder}>
                                                Save order
                                            </Button>
                                        )}
                                        <Tooltip
                                            placement="top"
                                            title={`${order ? "Disable" : "Enable"} order`}
                                        >
                                            <Button
                                                type={order ? "primary" : "default"}
                                                onClick={handleToggleOrder}
                                                size="small"
                                                icon={<SortAscendingOutlined />}
                                            />
                                        </Tooltip>
                                    </Space>
                                </Col>
                            </Row>
                        }
                        dataSource={items || []}
                        renderItem={(
                            { id, title, media, text_color }: SurveyQuestionOptionType,
                            i: number
                        ) => (
                            <List.Item
                                actions={
                                    order
                                        ? [
                                              <div key="up">
                                                  {i !== items.length - 1 && (
                                                      <DownCircleOutlined
                                                          onClick={() => handlePutDown(i)}
                                                      />
                                                  )}
                                              </div>,
                                              <div key="down">
                                                  {i !== 0 && (
                                                      <UpCircleOutlined
                                                          onClick={() => handlePutUp(i)}
                                                      />
                                                  )}
                                              </div>
                                          ]
                                        : [
                                              <Space direction="horizontal">
                                                  <Link
                                                      to={`/survey/${surveyId}/questions/${questionId}/options/${id}`}
                                                      key="link"
                                                  >
                                                      <EditOutlined />
                                                  </Link>
                                                  {identity?.data
                                                      .is_option_attach_media_visible && (
                                                      <>
                                                          {media === null ? (
                                                              <Button
                                                                  size="small"
                                                                  onClick={() =>
                                                                      setOptionIdForMedia(id)
                                                                  }
                                                                  key="media"
                                                              >
                                                                  Attach media
                                                              </Button>
                                                          ) : (
                                                              <Popconfirm
                                                                  placement="left"
                                                                  key="delete"
                                                                  title="Are you sure to detach the media item from this option?"
                                                                  okText="Detach"
                                                                  onConfirm={async () => {
                                                                      await detachMedia({
                                                                          optionId: id
                                                                      });
                                                                      refetchOptions();
                                                                  }}
                                                              >
                                                                  <Button
                                                                      size="small"
                                                                      onClick={() => {}}
                                                                      key="media"
                                                                  >
                                                                      Detach media
                                                                  </Button>
                                                              </Popconfirm>
                                                          )}
                                                      </>
                                                  )}
                                              </Space>,
                                              <Popconfirm
                                                  placement="left"
                                                  key="delete"
                                                  title="Are you sure to delete this option?"
                                                  okText="Delete"
                                                  onConfirm={() => handleDeleteOption(id)}
                                              >
                                                  <DeleteOutlined />
                                              </Popconfirm>
                                          ]
                                }
                            >
                                {surveyQuestion?.question_type === 0 ? (
                                    <>
                                        {media !== null ? (
                                            <img src={media.url} style={{ maxWidth: 200 }} alt="" />
                                        ) : (
                                            title?.en
                                        )}
                                    </>
                                ) : (
                                    <Space direction="horizontal">
                                        {media && (
                                            <img src={media.url} style={{ maxWidth: 200 }} alt="" />
                                        )}
                                        {text_color && (
                                            <Tag color={text_color}>
                                                <span
                                                    style={{
                                                        color: text_color.startsWith("#ffffff")
                                                            ? "black"
                                                            : "white"
                                                    }}
                                                >
                                                    {text_color}
                                                </span>
                                            </Tag>
                                        )}
                                        <Typography.Text>{title?.en}</Typography.Text>
                                    </Space>
                                )}
                            </List.Item>
                        )}
                    />
                    <Outlet />
                </Col>
                <GalleryDrawer
                    title={"Attach media to question option"}
                    setIsDrawerOpen={(_: any) => setOptionIdForMedia(null)}
                    isDrawerOpen={optionIdForMedia !== null}
                    gallery={gallery}
                    onAttachClick={async (itemId: string | number) => {
                        await attachMedia({
                            optionId: optionIdForMedia!,
                            mediaId: itemId
                        });
                        refetchOptions();
                        setOptionIdForMedia(null);
                    }}
                    isLoadingAttachMedia={isLoadingAttachMedia}
                />
            </Row>
        </div>
    );
};

export default QuestionOptions;
