import { MultilingualFormInput } from "@components/forms/multilingual";

export const newUserInputs = (): MultilingualFormInput[] => [
    {
        name: "name",
        label: "Name",
        multilingual: false,
        rules: [{ required: true, message: "Please insert a name for the user" }]
    },
    {
        name: "email",
        label: "Email",
        multilingual: false,
        type: "email",
        rules: [{ required: true, message: "Please insert an email for the user" }]
    },
    {
        name: "password",
        label: "Password",
        multilingual: false,
        type: "password",
        rules: [{ required: true, message: "Please insert a password for the user" }]
    },
    {
        name: "password_confirmation",
        label: "Confirm password",
        multilingual: false,
        type: "password",
        rules: [{ required: true, message: "Please insert a password confirmation for the user" }],
        formItemProps: {
            dependencies: ["password"],
            rules: [
                {
                    required: true,
                    message: "Please confirm the user's password"
                },
                ({ getFieldValue }: any) => ({
                    validator(_: any, value: any) {
                        if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error("The new password that you entered do not match!")
                        );
                    }
                })
            ]
        }
    }
];

export const updateUserInputs = (): MultilingualFormInput[] => [
    {
        name: "name",
        label: "Name",
        multilingual: false,
        rules: [{ required: true, message: "Please insert a name for the user" }]
    },
    {
        name: "email",
        label: "Email",
        multilingual: false,
        type: "email",
        rules: [{ required: true, message: "Please insert an email for the user" }]
    },
    {
        name: "password",
        label: "Password",
        multilingual: false,
        type: "password",
        formItemProps: {
            hasFeedback: true
        }
    },
    {
        name: "password_confirmation",
        label: "Confirm password",
        multilingual: false,
        type: "password",
        formItemProps: {
            dependencies: ["password"],
            hasFeedback: true,
            rules: [
                ({ getFieldValue }: any) => ({
                    validator(_: any, value: any) {
                        if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error("The new password that you entered do not match!")
                        );
                    }
                })
            ]
        }
    }
];
