import { Button, Col, PageHeader, Popover, Row, Spin } from "antd";
import { FormConfigsInterface, enhancedQuestionInputs } from "@utils/profileFormConfigs";
import { useCreateSurveyMutation, useGetTimezonesQuery } from "@store/surveys/surveys";
import { useEffect, useState } from "react";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual";
import { SurveyFormItems } from "./types";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import { selectUser } from "@store/slices/user";
import slugify from "slugify";
import { surveyInputs } from "./form/new-survey-inputs";
import { timeZoneToUtc } from "@utils/timezones";
import { useCanCreateSurvey } from "src/hooks/useCanCreateSurvey";
import { useCheckSlug } from "../../hooks/useCheckSlug";
import { useGetProfilesQuery } from "@store/profiles/profiles";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import OwnerConfirmationModal from "./modal/OwnerConfirmationModal";

const NewSurvey = () => {
    const [form] = MultilingualForm.useForm();
    const navigate = useNavigate();
    const [currentProfileId, setCurrentProfileId] = useState<number | null>(null);
    const [ownerName, setOwnerName] = useState<string | undefined>();
    const [ownerConfirmationModalOpen, setOwnerConfirmationModalOpen] = useState<boolean>(false);
    const [ownerConfirmationModalCallback, setOwnerConfirmationModalCallback] = useState<
        any | undefined
    >();

    const { identity } = useSelector(selectUser);
    const { validSlug, setSlugForValidation } = useCheckSlug({
        formInstance: form,
        profileId: currentProfileId
    });

    const { cannotCreateSurvey, errorProfileData } = useCanCreateSurvey(identity?.data.profile?.id);

    const {
        data: timezones,
        isFetching: isFetchingTimezones,
        isLoading: isLoadingTimezones,
        error: errorTimezones
    } = useGetTimezonesQuery("");

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData
    } = useGetProfilesQuery(
        {
            step: undefined
        },
        { skip: !identity || !identity?.data.is_admin }
    );

    const [createSurvey, { data, isLoading: isLoadingAddSurvey }] = useCreateSurveyMutation();

    const openOwnerConfirmationModal = (callbackValues: SurveyFormItems) => {
        setOwnerConfirmationModalOpen(true);
        setOwnerConfirmationModalCallback(callbackValues);
    };
    const closeOwnerConfirmationModal = () => {
        setOwnerConfirmationModalOpen(false);
        setOwnerConfirmationModalCallback(undefined);
    };

    const ownerConfirmationModalConfirmCallback = () => {
        if (ownerConfirmationModalCallback) {
            sendRequest(ownerConfirmationModalCallback);
        }
    };

    const sendRequest = async (values: SurveyFormItems) => {
        const openAtDate = timeZoneToUtc({ time: values.open_at, zone: values.timezone });
        const closeAtDate = timeZoneToUtc({ time: values.close_at, zone: values.timezone });
        await performMutation({
            mutation: async () =>
                await createSurvey({
                    ...values,
                    slug: values.slug || null,
                    profile_id: currentProfileId,
                    open_at: openAtDate,
                    close_at: closeAtDate
                }).unwrap(),
            onSuccessCallback: () => navigate("/"),
            successMessage: "The survey was created successfully",
            errorMessage: "There was an error while creating the survey",
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            formFieldsResetter: () => form.resetFields()
        });
    };
    const handleOnSubmit = async (values: SurveyFormItems) => {
        if (identity?.data?.profile?.form_configs?.survey?.owner_name?.is_visible) {
            openOwnerConfirmationModal(values);
        } else {
            return await sendRequest(values);
        }
    };

    const getOwnerConfirmationModalPassphrase = (): string | undefined => {
        return ownerName || identity?.data.email;
    };

    useEffect(() => {
        if (!identity?.data.is_admin) setCurrentProfileId(identity?.data.profile?.id || null);
    }, [identity]);

    const inputList = surveyInputs({
        timezones,
        slugPath: validSlug,
        isAdmin: identity?.data?.is_admin,
        profiles: profilesData?.profiles,
        profileId: currentProfileId,
        identity: identity
    });

    const formInputs =
        timezones && identity?.data
            ? identity.data.is_admin
                ? inputList
                : enhancedQuestionInputs({
                      inputs: inputList,
                      formConfigs: identity?.data.profile?.form_configs?.survey as
                          | FormConfigsInterface
                          | null
                          | undefined,
                      canDisableTitle: false
                  })
            : null;

    const error = errorTimezones || errorProfilesData || errorProfileData;
    const loading =
        isLoadingTimezones ||
        isFetchingTimezones ||
        isFetchingProfilesData ||
        isLoadingProfilesData ||
        isLoadingAddSurvey;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader onBack={() => navigate(`/`)} title="New survey" />
            <Row justify="center">
                <Col xs={23}>
                    <MultilingualForm
                        inputs={Array.isArray(formInputs) ? formInputs : []}
                        form={form}
                        onValuesChange={(changedValues: any) => {
                            if (changedValues.title && !data?.slug) {
                                const generatedSlug = slugify(
                                    changedValues.title[Object.keys(changedValues.title)[0]],
                                    { lower: true }
                                );

                                if (!form.isFieldTouched("slug")) {
                                    setSlugForValidation(generatedSlug);
                                    form.setFields([{ name: "slug", value: generatedSlug }]);
                                }
                            }
                            if (changedValues.slug) setSlugForValidation(changedValues.slug);
                            if (changedValues.profile_id)
                                setCurrentProfileId(parseInt(changedValues.profile_id));
                            if (changedValues.owner_name) {
                                setOwnerName(changedValues.owner_name);
                            }
                        }}
                        onSubmit={handleOnSubmit}
                        actions={
                            !!!formInputs?.length
                                ? undefined
                                : !cannotCreateSurvey
                                ? [
                                      <Button key="submit" type="primary" htmlType="submit">
                                          Create new survey
                                      </Button>
                                  ]
                                : [
                                      <Popover
                                          title="Survey limit reached"
                                          content={<>You cannot create a new survey at this time</>}
                                      >
                                          <Button
                                              key="submit"
                                              type="primary"
                                              htmlType="submit"
                                              disabled={cannotCreateSurvey}
                                          >
                                              Create new survey
                                          </Button>
                                      </Popover>
                                  ]
                        }
                    />
                </Col>
            </Row>
            <OwnerConfirmationModal
                open={ownerConfirmationModalOpen}
                onCancel={closeOwnerConfirmationModal}
                onOk={ownerConfirmationModalConfirmCallback}
                passphrase={getOwnerConfirmationModalPassphrase()}
                isLoading={loading}
            />
        </Spin>
    );
};

export default NewSurvey;
