import FileUpload from "@components/FileUpload";
import GalleryListItem from "@components/GalleryListItem";
import { GalleryItem } from "@store/surveys/types";
import { Drawer, List, Skeleton, Tabs } from "antd";
import React, { useState } from "react";

interface GalleryDrawerProps {
    title: string;
    isDrawerOpen: boolean;
    setIsDrawerOpen: Function;
    gallery: GalleryItem[] | undefined;
    onAttachClick: Function;
    isLoadingAttachMedia: boolean;
}

const GalleryDrawer: React.FC<GalleryDrawerProps> = ({
    title,
    setIsDrawerOpen,
    isDrawerOpen,
    gallery,
    onAttachClick,
    isLoadingAttachMedia
}) => {
    const [tabsActiveKey, setTabsActiveKey] = useState<string | null>(null);

    return (
        <Drawer
            title={title}
            placement="right"
            onClose={() => setIsDrawerOpen(false)}
            open={isDrawerOpen}
            size="large"
        >
            {gallery !== undefined ? (
                <Tabs
                    centered={true}
                    activeKey={tabsActiveKey || "upload"}
                    onChange={(key) => setTabsActiveKey(key)}
                >
                    <Tabs.TabPane tab="Upload New" key="upload">
                        <FileUpload onSuccessUpload={() => setTabsActiveKey("gallery")} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Use Existing From Gallery" key="gallery">
                        <List
                            itemLayout="vertical"
                            size="large"
                            loading={isLoadingAttachMedia}
                            pagination={{ pageSize: 3, position: "top" }}
                            dataSource={gallery}
                            renderItem={(item) => {
                                return (
                                    <GalleryListItem item={item} onAttachClick={onAttachClick} />
                                );
                            }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            ) : (
                <Skeleton />
            )}
        </Drawer>
    );
};

export default GalleryDrawer;
