import { Button, Col, PageHeader, Row, Spin } from "antd";
import { useGetProfileUsersQuery, useUpdateUserMutation } from "@store/profiles/profiles";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import MultilingualForm from "@components/forms/multilingual";
import { ProfileUserType } from "@store/profiles/types";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import { updateUserInputs } from "./form/users";
import { useEffect } from "react";
import { useParams } from "react-router";

export default () => {
    const { profile, user } = useParams();
    const [form] = MultilingualForm.useForm();

    const {
        data: users,
        isFetching: isFetchingUsers,
        isLoading: isLoadingUsers,
        error: errorUsers
    } = useGetProfileUsersQuery({ profile: profile }, { skip: !!!profile });

    const [updateUser, { isLoading: isLoadingUpdateUser }] = useUpdateUserMutation();

    const handleOnSubmit = async (values: any) => {
        await performMutation({
            mutation: async () =>
                await updateUser({
                    ...values,
                    profile_id: profile,
                    user
                }).unwrap(),
            successMessage: "User updated successfully",
            errorMessage: "There was an error while creating the user",
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            formFieldsResetter: () => form.resetFields()
        });
    };

    useEffect(() => {
        if (users && user) {
            const selectedUser = users?.find(
                (profileUser: ProfileUserType) => String(profileUser.id) === user
            );
            if (selectedUser) {
                form.resetFields();
                form.setFieldsValue(selectedUser);
            }
        }
    }, [form, users, user]);

    const error = errorUsers;
    const loading = isFetchingUsers || isLoadingUsers || isLoadingUpdateUser;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader title={`Update user`} />
            <Row justify="center">
                <Col xs={23}>
                    <MultilingualForm
                        inputs={updateUserInputs()}
                        onSubmit={handleOnSubmit}
                        form={form}
                        hideTabsIfSingleLanguage={true}
                        actions={[
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                data-testid="new-question-submit"
                            >
                                Update user
                            </Button>
                        ]}
                    />
                </Col>
            </Row>
        </Spin>
    );
};
