import { MultilingualFormInput } from "@components/forms/multilingual";
import { SurveySlotType } from "@store/surveys/types";
import { WarningOutlined } from "@ant-design/icons";
import inputs from "./intputs";

export const questionInputs = ({
    isMultipleSlotEnabled,
    futureSlots,
    isQuestionType1Selected,
    isOpenQuestionSelected
}: {
    isMultipleSlotEnabled: boolean;
    futureSlots: SurveySlotType[];
    isQuestionType1Selected: boolean;
    isOpenQuestionSelected: boolean;
}): MultilingualFormInput[] => {
    const inputArray = [
        ...inputs,
        {
            name: "description",
            label: "Description",
            type: "rich-text-editor"
        },
        {
            name: "is_open",
            label: "Is this an open question?",
            type: "switch",
            multilingual: false
        },
        {
            name: "min_number_of_answers",
            label: "Min number of answers",
            multilingual: false,
            inputProps: {
                addonAfter: (
                    <>
                        <WarningOutlined /> Cannot be &gt; 1 if 'Is radio' is ticked
                    </>
                )
            }
        },
        {
            name: "max_number_of_answers",
            label: "Max number of answers",
            multilingual: false,
            isInvisible: isOpenQuestionSelected
        },
        {
            name: "question_type",
            label: "Question type",
            type: "select",
            options: [
                { label: "Default", value: 0 },
                { label: "Range slider", value: 1 }
            ],
            multilingual: false,
            isInvisible: isOpenQuestionSelected
        },
        {
            name: "is_radio",
            label: "Is radio",
            type: "switch",
            multilingual: false,
            isInvisible: isOpenQuestionSelected || !!isQuestionType1Selected
        },
        {
            name: "is_icon",
            label: "Is icon",
            type: "switch",
            multilingual: false,
            isInvisible: isOpenQuestionSelected || !!isQuestionType1Selected
        }
    ];

    if (!isMultipleSlotEnabled) {
        inputArray.push({
            name: "is_published",
            label: "Is published",
            type: "switch",
            multilingual: false
        });
    }

    if (isMultipleSlotEnabled) {
        inputArray.push({
            name: "survey_slot_ids",
            label: "Future (and not yet published) question groups in which the question is published",
            type: "select",
            multilingual: false,
            inputProps: {
                mode: "multiple",
                options: futureSlots.map((slot) => {
                    return {
                        label: slot.title || "Slot with no title",
                        value: slot.id
                    };
                })
            }
        });
    }

    return inputArray as MultilingualFormInput[];
};
