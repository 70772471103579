import NoSelected from "@components/NoSelected";
import React from "react";

const ProfilesHome: React.FC = () => {
    return (
        <NoSelected
            title="Please select a profile or create a"
            buttonText="New profile"
            link="/profiles/new"
        />
    );
};

export default ProfilesHome;
