import { MultilingualFormInput } from "@components/forms/multilingual";

export const questionOptionInputs = ({
    isIcon,
    isQuestionSlider
}: {
    isIcon: boolean;
    isQuestionSlider: boolean;
}): MultilingualFormInput[] => {
    if (isIcon) {
        return [
            {
                name: "title",
                label: "Title",
                rules: [
                    { required: true, message: "Please insert a title for the question option" }
                ],
                type: "select",
                options: Array.from({ length: 11 }, (value, index) => index).map(
                    (number: number) => {
                        return {
                            label: String(number),
                            value: String(number)
                        };
                    }
                )
            }
        ];
    } else if (isQuestionSlider) {
        return [
            {
                name: "title",
                label: "Title",
                rules: [
                    { required: true, message: "Please insert a title for the question option" }
                ],
                type: "text"
            }
        ];
    } else
        return [
            {
                name: "title",
                label: "Title",
                rules: [
                    { required: true, message: "Please insert a title for the question option" }
                ]
            },
            {
                name: "subtitle",
                label: "Subtitle"
            },
            {
                name: "description",
                label: "Description",
                type: "rich-text-editor"
            }
        ];
};
