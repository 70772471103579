import { Button, Popconfirm } from "antd";
import { Layout, Left, Right } from "@components/layout";
import { Link, Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDeleteProfileMutation, useGetProfilesQuery } from "@store/profiles/profiles";
import { useNavigate, useParams } from "react-router";

import Card from "@components/Card/Card";
import ErrorMessage from "@components/ErrorMessage";
import { ProfileType } from "@store/profiles/types";
import moment from "moment";
import { performMutation } from "@utils/performMutation";
import { useDebouncedSearchParam } from "src/hooks/useDebouncedSearchParam";

const Surveys: React.FC = () => {
    const { profile } = useParams();
    const navigate = useNavigate();

    const { searchParam, setSearchParam, debouncedSearchParam } = useDebouncedSearchParam();
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setCurrentPage(1);
    }, [searchParam]);

    const {
        data: profilesData,
        isFetching: isFetchingProfiles,
        isLoading: isLoadingProfiles,
        error: errorProfiles
    } = useGetProfilesQuery({
        page: currentPage,
        searchTerm: debouncedSearchParam
    });

    // const [deleteProfile, { isLoading: isLoadingDeleteProfile }] = useDeleteProfileMutation();

    // const handleDeleteProfile = async ({ profile }: { profile: string }) => {
    //     await performMutation({
    //         mutation: async () =>
    //             await deleteProfile({
    //                 profile
    //             }).unwrap(),
    //         successMessage: "Profile deleted successfully",
    //         errorMessage: "There was an error while deleting the profile",
    //         onSuccessCallback: () => navigate("/profiles")
    //     });
    // };

    const error = errorProfiles;
    const loading = isFetchingProfiles || isLoadingProfiles;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Layout>
            <Left
                action={
                    <Link to="/profiles/new">
                        <Button type="primary" size="small">
                            + New
                        </Button>
                    </Link>
                }
                onSearch={setSearchParam}
                searchValue={searchParam}
                title="Profiles"
                loadingItems={loading}
                items={profilesData?.profiles || []}
                pagination={profilesData?.pagination}
                onPageChange={(page) => setCurrentPage(page)}
                renderItem={(prfl: ProfileType) => (
                    <Link to={`/profiles/${prfl.id}`} data-testid="profiles-card">
                        <Card
                            published={
                                prfl.created_at ? moment(prfl.created_at).fromNow() : undefined
                            }
                            updated={
                                prfl.updated_at ? moment(prfl.updated_at).fromNow() : undefined
                            }
                            title={prfl.name || prfl.name}
                            selected={profile !== undefined && String(prfl.id) === profile}
                            // deleteElement={
                            //     // Deletion of profiles
                            //     <Popconfirm
                            //         title="Are you sure you want to delete this profile?"
                            //         okText="Delete"
                            //         onConfirm={() =>
                            //             handleDeleteProfile({ profile: String(prfl.id) })
                            //         }
                            //     >
                            //         <DeleteOutlined />
                            //     </Popconfirm>
                            // }
                        />
                    </Link>
                )}
            />
            <Right>
                <Outlet />
            </Right>
        </Layout>
    );
};

export default Surveys;
