import { Alert, Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import {
    LoginFormFields,
    clearLoginErrors,
    login,
    selectIsLogging,
    selectIsLoggingErrors,
    selectUser
} from "@store/slices/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { CheckCircleOutlined } from "@ant-design/icons";
import { Error422 } from "@store/index";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import { performMutation } from "@utils/performMutation";
import styles from "./Login.module.scss";
import { useRequestPasswordResetMutation } from "@store/admin/admin";

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { identity } = useSelector(selectUser);
    const error = useSelector(selectIsLoggingErrors);
    const loading = useSelector(selectIsLogging);
    const [form] = Form.useForm();
    const [emailPasswordResetForm] = Form.useForm();
    const [resetPassowrdEmailModal, setResetPasswordEmailModal] = useState<boolean>(false);
    const [isSuccessResetPassword, setIsSuccessResetPassword] = useState<boolean>(false);

    const [requestPasswordReset, { isLoading: isLoadingPasswordReset }] =
        useRequestPasswordResetMutation();

    useEffect(() => {
        if (identity) {
            navigate(`/${location.search}`);
        }
    }, [identity, location.search, navigate]);

    useEffect(() => {
        if (error) {
            form.setFields([
                {
                    name: "email",
                    errors: [""]
                },
                {
                    name: "password",
                    errors: [error.status === 422 ? error.message : ""]
                }
            ]);
            dispatch(clearLoginErrors());
        }
    }, [error, form, dispatch]);

    const handleRequestPasswordReset = async (values: any) => {
        await performMutation({
            mutation: async () =>
                await requestPasswordReset({
                    email: values.email
                }).unwrap(),
            successMessage: "A password reset link has been sent to the specified email",
            errorMessage: "There was an error while requesting the password reset link",
            onSuccessCallback: () => {
                setIsSuccessResetPassword(true);
            },
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                emailPasswordResetForm.setFields(valuesWithErrors);
            }
        });
    };

    const handleSubmitForm = (values: LoginFormFields) => dispatch(login(values));

    return (
        <div className={styles.login}>
            <Row className={styles.row}>
                <Col lg={8} md={6} sm={0} xl={8} xxl={9}>
                    <div className={styles.fakeHeight} />
                </Col>
                <Col lg={8} md={12} sm={24} xl={8} xxl={6} className={styles.col}>
                    <div className={styles.formCard}>
                        <div className={styles.formLogo}>
                            <img src="/logo-adm-mbs.png" width="240" alt="will-logo" />
                        </div>
                        {/* <Typography.Title level={3}>Login</Typography.Title> */}
                        <div className={styles.form}>
                            <Form form={form} onFinish={handleSubmitForm} data-testid="login-form">
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: "Insert your email" }]}
                                >
                                    <Input placeholder="Type your email" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        { required: true, message: "Please input your password!" }
                                    ]}
                                >
                                    <Input.Password placeholder="Type your password" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        loading={loading}
                                        htmlType="submit"
                                        className={styles.btn}
                                        type="primary"
                                    >
                                        Log in
                                    </Button>
                                </Form.Item>
                            </Form>
                            <Button type="text" onClick={() => setResetPasswordEmailModal(true)}>
                                Forgot password?
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={8} md={6} sm={0} xl={8} xxl={9}>
                    <div className={styles.fakeHeight} />
                </Col>
            </Row>
            <Modal
                title="Request a password reset link"
                open={resetPassowrdEmailModal}
                footer={isSuccessResetPassword ? null : undefined}
                onOk={!isSuccessResetPassword ? emailPasswordResetForm.submit : undefined}
                confirmLoading={isLoadingPasswordReset}
                onCancel={() => setResetPasswordEmailModal(false)}
            >
                {isSuccessResetPassword ? (
                    <Alert
                        showIcon
                        icon={<CheckCircleOutlined />}
                        message="The password reset link has been sent to the specified email"
                    />
                ) : (
                    <Form form={emailPasswordResetForm} onFinish={handleRequestPasswordReset}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                    message: "The input is not valid E-mail!"
                                }
                            ]}
                        >
                            <Input type="email" placeholder="Type your email" />
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </div>
    );
};

export default Login;
