import {
    HomeProfileUser,
    HomeSurvey,
    HomeSurveyQuestions,
    Login,
    Logs,
    Metrics,
    NewProfileUser,
    NewSurvey,
    NewSurveyQuestion,
    NewSurveyQuestionOption,
    Page404,
    Profiles,
    ProfilesDetails,
    ProfilesHome,
    ProfilesUsers,
    ResetPassword,
    SurveyAnswers,
    SurveyDetails,
    SurveyFilesAndImages,
    SurveyQuestionOptions,
    SurveyQuestions,
    SurveySlots,
    SurveyStyles,
    Surveys,
    UpdateSurveyQuestion,
    UpdateSurveyQuestionOption,
    UpdateUser
} from "./pages";
import { Navigate, Route, Routes } from "react-router-dom";

import App from "./App";
import Hocs from "./hocs";
import { isWithSlots } from "@utils/isWithSlots";
import { selectUser } from "@store/slices/user";
import { useSelector } from "react-redux";

const AppRoutes = () => {
    const { identity } = useSelector(selectUser);

    const checkIsAdmin = (Element: React.ReactNode, isAdmin: boolean | undefined) => {
        if (isAdmin) return Element;
        else return <Navigate to="/" />;
    };

    return (
        <Hocs>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="/" element={<Surveys />}>
                        <Route index element={<HomeSurvey />} />
                        <Route path="/survey/:id" element={<SurveyDetails />} />
                        <Route path="/survey/:id/answers" element={<SurveyAnswers />} />
                        <Route path="/survey/:id/answers/:slot" element={<SurveyAnswers />} />
                        <Route path="/survey/new" element={<NewSurvey />} />
                        <Route path="/survey/:id/questions" element={<SurveyQuestions />}>
                            <Route index element={<HomeSurveyQuestions />} />
                            <Route path="new" element={<NewSurveyQuestion />} />
                            <Route path=":questionId" element={<UpdateSurveyQuestion />} />
                            <Route path=":questionId/options" element={<SurveyQuestionOptions />}>
                                <Route path="new" element={<NewSurveyQuestionOption />} />
                                <Route path=":optionId" element={<UpdateSurveyQuestionOption />} />
                            </Route>
                        </Route>
                        {/* SLOTS START */}
                        <Route path="/survey/:id/slots" element={<SurveySlots />} />
                        <Route path="/survey/:id/slots/:slotId" element={<SurveySlots />} />
                        {/* SLOTS END */}
                        <Route path="/survey/:id/styles" element={<SurveyStyles />} />
                        <Route
                            path="/survey/:id/files-and-images"
                            element={<SurveyFilesAndImages />}
                        />
                    </Route>
                    <Route
                        path="/profiles"
                        element={checkIsAdmin(<Profiles />, identity?.data?.is_admin)}
                    >
                        <Route index element={<ProfilesHome />} />
                        <Route path=":profile" element={<ProfilesDetails />} />
                        <Route path=":profile/users" element={<ProfilesUsers />}>
                            <Route index element={<HomeProfileUser />} />
                            <Route path="new" element={<NewProfileUser />} />
                            <Route path=":user" element={<UpdateUser />} />
                        </Route>
                    </Route>
                    <Route
                        path="/logs"
                        element={checkIsAdmin(<Logs />, identity?.data?.is_admin)}
                    ></Route>
                    <Route
                        path="/metrics"
                        element={checkIsAdmin(<Metrics />, identity?.data?.is_admin)}
                    ></Route>
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </Hocs>
    );
};

export default AppRoutes;
