import NoSelected from "@components/NoSelected";
import React from "react";
import { useParams } from "react-router";

const HomeUsers: React.FC = () => {
    const { profile } = useParams();

    return (
        <NoSelected
            title="Please select a user or create a"
            buttonText="New user"
            link={`/profiles/${profile}/users/new`}
        />
    );
};

export default HomeUsers;
