import {
    Button,
    Divider,
    Form,
    Modal,
    PageHeader,
    Select,
    Space,
    Spin,
    Table,
    Typography,
    message
} from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import surveysApi, {
    ANSWERS,
    useGetSurveyAnalyticsQuery,
    useGetSurveyAnswersQuery,
    useGetSurveyQuery,
    useGetSurveySlotsQuery
} from "@store/surveys/surveys";
import { useNavigate, useParams } from "react-router";

import { CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Page } from "@components/layout";
import {
    SurveyQuestionAnswerType,
    SurveyQuestionOptionType,
    SurveySlotType
} from "@store/surveys/types";
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import { selectUser } from "@store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { isWithSlots } from "@utils/isWithSlots";

interface DataType {
    id: string;
    signature: number;
    question: any;
    option: any;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
        key: "question",
        title: "Question",
        dataIndex: "question",
        render: ({ title }) => {
            let formattedTitle = "";
            try {
                formattedTitle = title[Object.keys(title)[0]];
            } catch {
                formattedTitle = "";
            }

            return <div data-testid="answer-question-title">{formattedTitle}</div>;
        }
    },
    {
        key: "options",
        title: "Answers",
        dataIndex: "options",
        render: ((options: SurveyQuestionOptionType[], answer: SurveyQuestionAnswerType) => {
            if (answer?.question?.is_open) return <div>{answer?.handwritten || ""}</div>;
            else {
                return options?.map((option) => {
                    let formattedTitle = "";
                    try {
                        formattedTitle = option?.title?.[Object.keys(option.title)[0]] || "";
                    } catch {
                        formattedTitle = "";
                    }
                    return <div key={formattedTitle}>{formattedTitle}</div>;
                });
            }
        }) as any
    },
    {
        key: "signature",
        title: "Signature",
        dataIndex: "signature"
    },
    {
        key: "created_at",
        title: "Date",
        dataIndex: "created_at",
        render: (data) => moment(data).format("DD/MM/YYYY HH:mm:ss")
    }
];

const DEFAULT_PAGE = 1;

const Answers: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, slot } = useParams();
    const { token, identity } = useSelector(selectUser);
    const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
    const [downloadingExport, toggleDownloadingExport] = useState<boolean>(false);
    const [cleaningAnswers, setCleaningAnswers] = useState<boolean>(false);
    const [cleanAnswersModalOpen, setCleanAnswersModalOpen] = useState<boolean>(false);
    const [form] = Form.useForm();

    const {
        data: survey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        error: errorSurvey
    } = useGetSurveyQuery(id!);

    const {
        data: surveySlots,
        isLoading: isLoadingSurveySlots,
        isFetching: isFetchingSurveySlot,
        error: errorSurveySlots
    } = useGetSurveySlotsQuery({ surveyId: id! });

    const {
        data: surveyAnalytics,
        isLoading: isLoadingSurveyAnalytics,
        isFetching: isFetchingSurveyAnalytics,
        error: errorSurveyAnalytics
    } = useGetSurveyAnalyticsQuery({ surveyId: id! }, { skip: !!!id });

    const isValidSlot =
        !!slot && !!surveySlots?.find((slt: { id: number }) => slt.id === parseInt(slot));

    const {
        data: answersData,
        isFetching: isFetchingAnswersData,
        isLoading: isLoadingAnswersData,
        error: errorAnswersData,
        refetch: refetchAnswers
    } = useGetSurveyAnswersQuery(
        {
            page: currentPage,
            surveyId: id!,
            surveySlot: isValidSlot ? slot : null
        },
        { skip: surveySlots === undefined }
    );

    const handleDownloadFile = async () => {
        if (!downloadingExport) {
            toggleDownloadingExport(true);

            const url = isValidSlot
                ? `${process.env.REACT_APP_API_BASE_URL}/admin/surveys/${id}/export?survey_slot_id=${slot}`
                : `${process.env.REACT_APP_API_BASE_URL}/admin/surveys/${id}/export`;

            try {
                const res = await axios.get(url, {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: "blob"
                });
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                const fileName = isValidSlot ? `${id}-slot-${slot}` : id;
                saveAs(blob, fileName);
            } catch (error: any) {
                message.error(error.message);
            }
            toggleDownloadingExport(false);
        }
    };

    const handleCleanAnswers = async () => {
        if (!cleaningAnswers) {
            setCleaningAnswers(true);

            const url = `${process.env.REACT_APP_API_BASE_URL}/admin/surveys/${id}/clean-answers`;
            const data = isValidSlot
                ? {
                      survey_slot_id: slot
                  }
                : {};
            try {
                const res = await axios.post(url, data, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (res?.data?.status) {
                    refetchAnswers();
                    dispatch(surveysApi.util.invalidateTags([{ type: ANSWERS, id: "LIST" }]));
                    message.success(`Answers list cleared.`);
                    closeCleanAnswersListModal();
                } else {
                    message.error(`Nothing happened.`);
                }
            } catch (error: any) {
                message.error(error.message);
            } finally {
                setCleaningAnswers(false);
            }
        }
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || DEFAULT_PAGE);
    };

    useEffect(() => {
        if (form && surveySlots) {
            if (isValidSlot) {
                form.setFieldsValue({
                    slot: parseInt(slot)
                });
            } else navigate(`/survey/${id}/answers`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, surveySlots, isValidSlot]);

    const openCleanAnswersListModal = () => {
        setCleanAnswersModalOpen(true);
    };

    const closeCleanAnswersListModal = () => {
        setCleanAnswersModalOpen(false);
    };

    const isExportButtonDisabled = () => {
        return isWithSlots(survey?.profile) && surveySlots && surveySlots?.length > 1 && !slot;
    };

    const error = errorSurvey || errorSurveySlots || errorAnswersData;
    const loading =
        isLoadingSurvey ||
        isFetchingSurvey ||
        isLoadingSurveySlots ||
        isFetchingSurveySlot ||
        isLoadingSurveyAnalytics ||
        isFetchingSurveyAnalytics ||
        isLoadingAnswersData ||
        isFetchingAnswersData;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader
                onBack={() => navigate(`/survey/${id}`)}
                title="Survey reports"
                extra={[
                    <Space direction="horizontal" align="end">
                        <Button
                            loading={downloadingExport}
                            onClick={handleDownloadFile}
                            icon={<CloudDownloadOutlined />}
                            disabled={isExportButtonDisabled()}
                            key="export"
                            data-testid="export-answers-button"
                        >
                            {isValidSlot && isWithSlots(survey?.profile)
                                ? "Export answers of the selected slot in xlsx format"
                                : "Export all answers in xlsx format"}
                        </Button>
                        {identity?.data.is_admin && (
                            <Button
                                loading={cleaningAnswers}
                                onClick={openCleanAnswersListModal}
                                icon={<DeleteOutlined />}
                                disabled={cleaningAnswers}
                                key="clean"
                                data-testid="clean-answers-button"
                            >
                                {isValidSlot && isWithSlots(survey?.profile)
                                    ? "Delete answers of the selected slot"
                                    : "Delete all answers"}
                            </Button>
                        )}

                        <Modal
                            title="Clear answers"
                            open={cleanAnswersModalOpen}
                            okText={"Delete"}
                            onOk={handleCleanAnswers}
                            confirmLoading={cleaningAnswers}
                            onCancel={() => closeCleanAnswersListModal()}
                            cancelText="Close"
                            okButtonProps={{
                                disabled: cleaningAnswers
                            }}
                            cancelButtonProps={{
                                disabled: cleaningAnswers
                            }}
                        >
                            By clickin "OK" button you are going to clear the selected answers list.
                            This action is irreversible, the data will be permanently deleted.
                            <br />
                            Are you sure you want to continue?
                        </Modal>
                        {isWithSlots(survey?.profile) && surveySlots && surveySlots?.length > 0 && (
                            <Form form={form} layout="vertical">
                                <Form.Item style={{ margin: 0 }} label="Slot selection" name="slot">
                                    <Select
                                        placeholder="Select the slot"
                                        style={{ width: 200 }}
                                        allowClear
                                        options={surveySlots.map((slot: SurveySlotType) => {
                                            return {
                                                label: slot.title,
                                                value: slot.id
                                            };
                                        })}
                                        onChange={(value) =>
                                            typeof value === "number"
                                                ? navigate(`/survey/${id}/answers/${value}`)
                                                : navigate(`/survey/${id}/answers`)
                                        }
                                    />
                                </Form.Item>
                            </Form>
                        )}
                    </Space>
                ]}
            />
            <Page>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Divider>Analytics</Divider>
                    <div>
                        <Typography.Title level={5}>Analytics data</Typography.Title>
                        <div>
                            <Typography.Text type="secondary">
                                Total number of unique visitors:{" "}
                            </Typography.Text>
                            <Typography.Text>
                                {!!errorSurveyAnalytics
                                    ? "Error! No data is available"
                                    : surveyAnalytics?.unique_visitors}
                            </Typography.Text>
                        </div>
                    </div>
                    <Divider>Survey answers</Divider>
                    {!isLoadingAnswersData && (
                        <Table
                            bordered
                            rowKey="id"
                            dataSource={answersData?.answers || []}
                            columns={
                                isWithSlots(survey?.profile)
                                    ? [
                                          ...columns,
                                          {
                                              title: "Slot",
                                              render: ({ survey_slot_id }) => {
                                                  const selectedSlot = surveySlots?.find(
                                                      (slot) => slot.id === survey_slot_id
                                                  );
                                                  return identity?.data.is_admin
                                                      ? `${selectedSlot?.title} (${survey_slot_id})`
                                                      : selectedSlot?.title;
                                              }
                                          }
                                      ]
                                    : [...columns]
                            }
                            onChange={handleTableChange}
                            pagination={{
                                total: answersData?.pagination.total || 0,
                                defaultCurrent: currentPage,
                                pageSize: answersData?.pagination.perPage || 0
                            }}
                        />
                    )}
                </Space>
            </Page>
        </Spin>
    );
};

export default Answers;
