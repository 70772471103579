import { Avatar, Dropdown, Layout, Spin, Typography, message } from "antd";
import React, { useEffect } from "react";
import { UserDeleteOutlined, UserOutlined } from "@ant-design/icons";
import {
    clearLogoutErrors,
    clearUser,
    selectIsLogoutError,
    selectIsLogoutLoading,
    selectUser
} from "@store/slices/user";
import { useDispatch, useSelector } from "react-redux";

import { Outlet } from "react-router-dom";
import TopMenu from "@components/TopMenu/TopMenu";
import styles from "./App.module.scss";

const { Header, Content } = Layout;

const App: React.FC = () => {
    const dispatch = useDispatch();
    const logoutLoading = useSelector(selectIsLogoutLoading);
    const logoutError = useSelector(selectIsLogoutError);
    const { identity } = useSelector(selectUser);

    useEffect(() => {
        if (logoutError) {
            message.error(logoutError.message).then(() => dispatch(clearLogoutErrors()));
        }
    }, [logoutError, dispatch]);

    const handleLogOut = () => {
        dispatch(clearUser());
    };

    return (
        <div data-testid="app" className={styles.app}>
            <Layout>
                <Header className={styles.header}>
                    <div className={styles.left}>
                        <div className={styles.logo}>
                            <img src="/logo-adm-mbs.png" alt="will-logo" />
                        </div>
                        {identity?.data?.is_admin && <TopMenu />}
                    </div>
                    <div className={styles.right}>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: "name",
                                        disabled: true,
                                        label: (
                                            <Typography.Title
                                                level={5}
                                                type="secondary"
                                                style={{ padding: "5px 10px 0" }}
                                            >
                                                {identity?.data.name}
                                            </Typography.Title>
                                        )
                                    },
                                    {
                                        key: "logout",
                                        icon: <UserDeleteOutlined />,
                                        onClick: handleLogOut,
                                        label: (
                                            <Spin key="logout" spinning={logoutLoading}>
                                                Log out
                                            </Spin>
                                        )
                                    }
                                ]
                            }}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Avatar
                                data-testid="header-user-avatar"
                                className={styles.avatar}
                                icon={<UserOutlined />}
                            />
                        </Dropdown>
                    </div>
                </Header>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
        </div>
    );
};

export default App;
