import { Button, Col, PageHeader, Row, Spin } from "antd";
import { FormConfigsInterface, enhancedQuestionInputs } from "@utils/profileFormConfigs";
import { timeZoneToUtc, utcToTimeZone } from "@utils/timezones";
import { useEffect, useState } from "react";
import {
    useGetSurveyQuery,
    useGetTimezonesQuery,
    useUpdateSurveyMutation
} from "@store/surveys/surveys";
import { useNavigate, useParams } from "react-router";

import { Error422 } from "@store/index";
import ErrorMessage from "@components/ErrorMessage";
import { Link } from "react-router-dom";
import MultilingualForm, { MultilingualFormInput } from "@components/forms/multilingual";
import { SurveyFormItems } from "./types";
import { mapErrorsToFields } from "@utils/mapErrorsToFields";
import moment from "moment";
import { performMutation } from "@utils/performMutation";
import { scrollToFirstFormError } from "@utils/scrollToFirstFormError";
import { selectUser } from "@store/slices/user";

import { surveyInputs } from "./form/new-survey-inputs";
import { useCheckSlug } from "src/hooks/useCheckSlug";
import { useGetProfilesQuery } from "@store/profiles/profiles";
import { useSelector } from "react-redux";
import { FormFieldConfig } from "@store/profiles/types";

const Details = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = MultilingualForm.useForm();
    const { identity } = useSelector(selectUser);
    const [currentProfileId, setCurrentProfileId] = useState<number | null>(null);

    const {
        data: profilesData,
        isFetching: isFetchingProfilesData,
        isLoading: isLoadingProfilesData,
        error: errorProfilesData
    } = useGetProfilesQuery(
        { step: undefined },
        { skip: identity === undefined || !identity?.data?.is_admin }
    );

    const {
        data: survey,
        isLoading: isLoadingSurvey,
        isFetching: isFetchingSurvey,
        error: errorSurvey,
        refetch: refetchSurvey
    } = useGetSurveyQuery(id!);

    const {
        data: timezones,
        isFetching: isFetchingTimezones,
        error: errorTimezones
    } = useGetTimezonesQuery("");
    const [updateSurvey, { isLoading: isLoadingUpdateSurvey }] = useUpdateSurveyMutation();

    useEffect(() => {
        if (survey) {
            form.resetFields();
            const openConverted = utcToTimeZone({ time: survey.open_at, zone: survey.timezone });
            const closeConverted = utcToTimeZone({ time: survey.close_at, zone: survey.timezone });

            form.setFieldsValue({
                ...survey,
                close_at: closeConverted ? moment(closeConverted) : undefined,
                open_at: openConverted ? moment(openConverted) : undefined,
                is_active: !!survey.is_active,
                profile_id: survey.profile_id ? String(survey.profile_id) : null
            });
        }
    }, [form, survey]);

    const handleOnSubmit = async (values: SurveyFormItems) => {
        const openAtDate = timeZoneToUtc({ time: values.open_at, zone: values.timezone });
        const closeAtDate = timeZoneToUtc({ time: values.close_at, zone: values.timezone });

        await performMutation({
            mutation: async () =>
                await updateSurvey({
                    id,
                    ...values,
                    slug: values.slug || null,
                    profile_id: currentProfileId,
                    open_at: openAtDate,
                    close_at: closeAtDate
                }).unwrap(),
            successMessage: "The survey was updated successfully",
            errorMessage: "There was an error while updating the survey",
            formFieldsResetter: () => form.resetFields(),
            formFieldsSetter: (error: Error422) => {
                const valuesWithErrors = mapErrorsToFields({ error, values });
                form.setFields(valuesWithErrors);
            },
            onSuccessCallback: () => refetchSurvey(),
            onErrorCallback: () => scrollToFirstFormError()
        });
    };

    const { validSlug, resetSlug, setSlugForValidation } = useCheckSlug({
        formInstance: form,
        slugFromSurvey: survey?.slug,
        surveyId: id,
        profileId: currentProfileId
    });

    useEffect(() => {
        if (survey) {
            resetSlug();
            setCurrentProfileId(survey.profile_id);
        }
    }, [survey]);

    const inputList = surveyInputs({
        timezones,
        slugPath: validSlug,
        isAdmin: identity?.data?.is_admin,
        profiles: profilesData?.profiles,
        profileId: currentProfileId,
        identity: identity
    });

    let surveyFormConfig = identity?.data.profile?.form_configs?.survey as
        | FormConfigsInterface
        | null
        | undefined;

    if (surveyFormConfig) {
        surveyFormConfig = {
            ...surveyFormConfig,
            owner_name: {
                ...surveyFormConfig.owner_name,
                editable: false
            }
        };
    }

    const withProfileIdDisabled = (inputList: MultilingualFormInput[]) => {
        return inputList.map((input) => {
            if (input.name === "profile_id") {
                return {
                    ...input,
                    inputProps: { ...input.inputProps, disabled: true },
                    alertMessage:
                        "It is not possible to change the profile associated to an already created survey"
                };
            } else return input;
        });
    };

    const formInputs =
        timezones && identity?.data
            ? identity.data.is_admin
                ? withProfileIdDisabled(inputList)
                : enhancedQuestionInputs({
                      inputs: inputList,
                      formConfigs: surveyFormConfig,
                      canDisableTitle: false
                  })
            : null;

    const error = errorSurvey || errorTimezones || errorProfilesData;
    const loading =
        isLoadingSurvey ||
        isFetchingSurvey ||
        isLoadingProfilesData ||
        isFetchingProfilesData ||
        isLoadingUpdateSurvey ||
        isFetchingTimezones;

    if (error) return <ErrorMessage message="There was an error, please try again later" />;
    return (
        <Spin spinning={loading}>
            <PageHeader
                onBack={() => navigate(`/`)}
                title="Update survey"
                extra={[
                    <>
                        <Link key="questions" to={`/survey/${id}/questions`}>
                            <Button>Questions</Button>
                        </Link>
                        <Link key="answers" to={`/survey/${id}/answers`}>
                            <Button>Reports</Button>
                        </Link>
                        {identity?.data.is_style_tab_visible && (
                            <Link key="styles" to={`/survey/${id}/styles`}>
                                <Button>Styles</Button>
                            </Link>
                        )}
                        {identity?.data.is_media_tab_visible && (
                            <Link key="files-and images" to={`/survey/${id}/files-and-images`}>
                                <Button>Files and Images</Button>
                            </Link>
                        )}
                    </>
                ]}
            />
            <Row justify="center">
                <Col xs={23}>
                    <MultilingualForm
                        form={form}
                        inputs={Array.isArray(formInputs) ? formInputs : []}
                        onValuesChange={(changedValues: any, values: any) => {
                            if (changedValues.slug) {
                                setSlugForValidation(changedValues.slug);
                            }
                            if (changedValues.profile_id) {
                                setSlugForValidation(values.slug);
                                setCurrentProfileId(parseInt(changedValues.profile_id));
                            }
                        }}
                        onSubmit={handleOnSubmit}
                        actions={
                            formInputs?.length
                                ? [
                                      <Button key="submit" type="primary" htmlType="submit">
                                          Update survey
                                      </Button>
                                  ]
                                : undefined
                        }
                    />
                </Col>
            </Row>
        </Spin>
    );
};

export default Details;
