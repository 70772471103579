import { Alert, Form, FormInstance, Tabs } from "antd";
import { MultilingualFormInput, MultilingualFormProps } from ".";
import React, { Ref, useImperativeHandle, useState } from "react";

import Items from "@components/forms/multilingual/Items";
import detectInputs from "./detect-inputs";
import { getDefaultLanguages } from "@utils/i18n";
import styles from "./Form.module.scss";
import { useRef } from "react";

const MultilingualForm: React.ForwardRefExoticComponent<MultilingualFormProps> & {
    useForm: typeof Form.useForm;
} = React.forwardRef(
    (
        { inputs, onSubmit, actions, form, onValuesChange, errors, style }: MultilingualFormProps,
        ref: Ref<FormInstance>
    ) => {
        const formWrapperRef = useRef<any>(null);
        const [multilingualInputs, withoutLangInputs] = detectInputs(inputs);
        const [tabsWithErrors, changeTabsWithErrors] = useState<string[]>([]);
        const languages = getDefaultLanguages();

        const handleOnErrors = (errors: any) => {
            scrollToFirstError();
            const state: string[] = [];
            errors.errorFields.forEach(({ name: [, lang] }: any) => {
                if (lang && !state.includes(lang)) {
                    state.push(lang);
                }
            });

            changeTabsWithErrors(state);
        };

        const scrollToFirstError = () => {
            if (!formWrapperRef.current) return;
            const itemWithError = formWrapperRef.current.querySelector(".ant-form-item-has-error");
            if (itemWithError) {
                itemWithError.scrollIntoView({
                    behavior: "smooth"
                });
            }
        };

        const handleOnSubmit = (values: any) => {
            changeTabsWithErrors([]);
            if (onSubmit) {
                onSubmit(values);
            }
        };

        return (
            <div ref={formWrapperRef}>
                <Form
                    layout="vertical"
                    style={style}
                    form={form}
                    ref={ref}
                    scrollToFirstError={true}
                    onFinishFailed={handleOnErrors}
                    onFinish={handleOnSubmit}
                    className={styles.form}
                    onValuesChange={onValuesChange || undefined}
                >
                    {languages.length > 1 ? (
                        <>
                            <Tabs className={`header-without-padding ${styles.tabs}`} type="card">
                                {languages.map((lang) => (
                                    <Tabs.TabPane
                                        tab={
                                            <div
                                                className={
                                                    tabsWithErrors.includes(lang)
                                                        ? styles.tabWithErrors
                                                        : styles.tabWithoutErrors
                                                }
                                            >
                                                {lang.toUpperCase()}
                                            </div>
                                        }
                                        key={lang}
                                        forceRender
                                    >
                                        <Items items={multilingualInputs} lang={lang} />
                                    </Tabs.TabPane>
                                ))}
                            </Tabs>
                            <Items items={withoutLangInputs} />
                        </>
                    ) : (
                        <>
                            {inputs.map((input: MultilingualFormInput) => {
                                return (
                                    <Items
                                        items={[input]}
                                        lang={
                                            input.multilingual === false ? undefined : languages[0]
                                        }
                                        key={input.name}
                                    />
                                );
                            })}
                        </>
                    )}

                    {errors && (
                        <>
                            <div style={{ paddingTop: 15 }} />
                            <Alert
                                style={{ marginBottom: 15 }}
                                closable
                                type="error"
                                message={errors}
                            ></Alert>
                        </>
                    )}
                    {actions && (
                        <>
                            <div style={{ paddingTop: 15 }} />
                            <Form.Item>{actions}</Form.Item>
                        </>
                    )}
                </Form>
            </div>
        );
    }
) as any;

MultilingualForm.useForm = Form.useForm;

export default MultilingualForm;
