import { Editor } from "@tinymce/tinymce-react";
import React from "react";

export interface RichTextEditorProps {
    placeholder?: string;
    value?: any;
    disabled?: boolean;
    onChange?: (value: any) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
    placeholder,
    value,
    disabled,
    onChange
}) => {
    return (
        <div style={disabled ? { opacity: "0.4" } : undefined}>
            <Editor
                onEditorChange={onChange}
                apiKey={process.env.REACT_APP_TINY_LICENCE}
                value={value}
                disabled={disabled}
                init={{
                    height: 300,
                    menubar: false,
                    forced_root_block: false,
                    deprecation_warnings: false,
                    placeholder,
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount autoresize"
                    ],
                    toolbar: "undo redo |" + "bold italic backcolor | " + "removeformat |"
                }}
            />
        </div>
    );
};

export default RichTextEditor;
