export const scrollToFirstFormError = () => {
    setTimeout(() => {
        const itemWithError = document.querySelector(".ant-form-item-has-error");
        if (itemWithError) {
            itemWithError.scrollIntoView({
                behavior: "smooth"
            });
        }
    }, 10);
};
