import { Moment } from "moment";
import moment from "moment";
import momentTimezone from "moment-timezone";

const dateFormat = "YYYY-MM-DD HH:mm:ss";

export const utcToTimeZone = ({
    time,
    zone,
    format
}: {
    time: string | null | undefined;
    zone?: string | null;
    format?: string;
}): string | null => {
    if (!time) return null;
    const unformatted = momentTimezone.utc(time).tz(zone || "utc");
    return unformatted.format(format || dateFormat);
};

export const timeZoneToUtc = ({
    time,
    zone
}: {
    time: string | Moment | null | undefined;
    zone: string | undefined;
}): string | null => {
    if (!time) return null;
    return momentTimezone
        .tz(moment(time)?.set({ second: 0 })?.format(dateFormat), dateFormat, zone || "utc")
        .utc()
        .format(dateFormat);
};
